export const Profile =()=>{
    return <div>hello profile</div>
}

export const Userlist = ()=>{
    return <div>userlist page</div>
}

export const Editor = ()=>{
    return <div>editor page</div>
}

export const Admin =()=>{
    return <div>hello admin</div>
}