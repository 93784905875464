import { useState, useRef, useEffect } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Divider, Input, Select, Space, Button } from 'antd';
import type { InputRef } from 'antd';
import axios from 'axios'
import { url_config } from '../../../url_config'



type ItemState = Partial<{ id: number, name: string }[]>;

interface Props{
    handleSelectChange: Function,
    defaultSeries: number
}

const defaultItem = [
    {
        id: 0,
        name: '不选择'
    }
]

const SeriesDropDown: React.FC<Props> = ({handleSelectChange,defaultSeries}) => {
    const [items, setItems] = useState<ItemState>(defaultItem);
    const [name, setName] = useState('');
    const inputRef = useRef<InputRef>(null);

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };
    useEffect(() => {
        axios.get(url_config.getSeries).then(res => {
            if (res.status === 200) {
                setItems([...defaultItem,...res.data])
            }
        })
    }, [])
    const handleChange = (value:number)=>{
      handleSelectChange(value)
    }

    const addItem = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        e.preventDefault();
        axios.post(url_config.getSeries, { name: name }).then(res => {
            if (res.status === 201) {
                setItems([...items, res.data])
                setName('');
                setTimeout(() => {
                    inputRef.current?.focus();
                }, 0);
            }

        })

    };
    return (
        <div>
            <Select
                style={{ width: 300 }}
                placeholder="系列选择"
                onChange={handleChange}
                defaultValue={defaultSeries}
                dropdownRender={(menu) => (
                    <>
                        {menu}
                        <Divider style={{ margin: '8px 0' }} />
                        <Space style={{ padding: '0 8px 4px' }}>
                            <Input
                                placeholder="Please enter item"
                                ref={inputRef}
                                value={name}
                                onChange={onNameChange}
                            />
                            <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                                Add item
                            </Button>
                        </Space>
                    </>
                )}
                options={items.map((item) => ({ label: item?.name, value: item?.id }))}
            />
        </div>
    )
}

export default SeriesDropDown