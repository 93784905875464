import {
  BlogContent,
  BlogContenRight,
  BlogContentLeft,
  BriefPannel,
  Hr
} from "./Blog.style";
import { useNavigate } from "react-router-dom";
import BlogBrief from "./BlogBrief";
import Series from "../../components/Series/Series";
import Tags from "../../components/Tags/Tags";
import RecentVisit from "./RecentVisit";
import NavBar from "../../components/NavBar/NavBar";
import { BlogNav } from "../../components/NavBar/navbar.style";
import { Article } from "../Home/\bArticleList";
import { useState } from "react";
import useGetArticles from "../../hooks/useGetArticles";
import useWindowSize from "../../hooks/useWindowSize";
import { url_config } from "../../url_config";



const navItems = ['常用', '最近']

function Blog() {
  const navigate = useNavigate()
  const [condition, setCondition] = useState('often_use')
  const {width} = useWindowSize()
  const changeEvent = (index: number) => {
    return new Promise((resolve, reject) => {
      if (index === 0) {
        setCondition('often_use')
      } else {
        setCondition('deliver_time')
      }
      resolve(index)
    })
  }
  const extractPTags = (htmlString:string)=> {
    // Create a temporary element to hold the HTML string
    var tempElement = document.createElement('div');
    tempElement.innerHTML = htmlString;
  
    // Find all the p tags inside the temporary element
    var pTags = tempElement.getElementsByTagName('p');
  
    // Create an array to hold the extracted content
    var extractedContent = [];
  
    // Loop through each p tag and extract its content
    for (var i = 0; i < pTags.length; i++) {
      extractedContent.push(pTags[i].innerHTML);
    }
  
    // Return the extracted content array
    return extractedContent;
  }

  const dealArticle = (article: any) => {
    article.forEach((i: any) => {
      i.briefImg = url_config.base_url + '/media' + i.briefImg.split('media')[1]
      i.DetailImg = url_config.base_url + '/media' + i.DetailImg.split('media')[1]
      try {
        const htmlStr = i.content.split('</h2>')[1].split('<h2>')[0]
        let joinedPtag = extractPTags(htmlStr).join('。 ')
        if (joinedPtag.length>100){
          joinedPtag = joinedPtag.slice(0,100)+'...'
        }
        i.brief = joinedPtag
        // i.brief = i.content.split('</h2>')[1].split('<h2>')[0].replace(regex, "");
      } catch (error) {
        
      }
      
    })
    return article
  }
  const articles: any = dealArticle(useGetArticles<Article[]>({ sortBy: condition }))
  const recent:Article[] = useGetArticles<Article[]>({ sortBy: 'last_visit' })
  const handleNav = () => {
    navigate('/archive')
  }
 
  return (
    <BlogContent>
      <BlogContentLeft>
        <BriefPannel>
          <svg className="addIcon">
            <path
              d="M9 9H3v1h6v6h1v-6h6V9h-6V3H9v6z"
              fillRule="evenodd"
            ></path>
          </svg>
          <BlogNav>
            <NavBar navItems={navItems} changeEvent={changeEvent} ></NavBar>
          </BlogNav>
          <span className="Archive" onClick={handleNav}>Archive &gt;</span>
        </BriefPannel>
        {articles.map((item: any) => <BlogBrief key={item.id} article={item} />)}
      </BlogContentLeft>
      { width && width > 900 && <BlogContenRight>
        <Series />
        <Hr />
        <Tags />
        <Hr />
        <RecentVisit article={recent}/>
      </BlogContenRight>}
      
    </BlogContent>
  );
}

export default Blog;
