import { useEffect, useRef, useState } from 'react'
import { ModalContainer, ModalOverlay, ModalBox } from './modal.style'
import ReactDOM from "react-dom"


export default function Modal(props) {
    const { display, outSideClose } = props
    const [show, setShow] = useState(false)

    const overlayRef = useRef()
    const boxRef = useRef()
    const isInnerControlled = !(typeof display === 'boolean')
    const animateTime = props.animateTime ? props.animateTime : 500
    useEffect(() => {
        if (!isInnerControlled) {
            setShow(display)
        }
    }, [display, isInnerControlled])

    useEffect(() => {
        const overlayTarget = overlayRef.current
        if (overlayTarget && boxRef.current) {
            overlayTarget.addEventListener('click', (event) => {
                if (event.target === boxRef.current || boxRef.current.contains(event.target)) {
                    //点击的是modal内部
                } else {
                    handleClose()
                }
            })
        }
        return () => {
            if (overlayTarget) {
                overlayTarget.removeEventListener('click', () => {
                    console.log('removed')
                })
            }
        }
    })


    useEffect(() => {
        if (display || show) {
            if (overlayRef.current) overlayRef.current.style.setProperty('--animate-duration', `${animateTime}ms`);
            document.querySelector('body').style.overflow = 'hidden'
        }

        return () => {
            document.querySelector('body').style.overflow = 'auto'
        }
    }, [display, show,animateTime])

    const handleClose = () => {
        boxRef.current.classList.remove(props.animateInClass)
        boxRef.current.classList.add(props.animateOutClass)
        overlayRef.current.classList.remove("animate__fadeIn")
        overlayRef.current.classList.add("animate__fadeOut")
        setTimeout(() => {
            if (isInnerControlled) {
                setShow(false)

            } else {
                outSideClose()
            }
        }, animateTime)
    }
    const CloseIcon = <div className="close-icon-outer"><svg onClick={handleClose} t="1669105182596" className="close-icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2693" width="64" height="64"><path d="M499.2 951.466667c-234.666667 0-426.666667-192-426.666667-426.666667 0-17.066667 0-38.4 4.266667-55.466667 4.266667-12.8 12.8-17.066667 25.6-17.066666 12.8 4.266667 17.066667 12.8 17.066667 25.6-4.266667 12.8-4.266667 29.866667-4.266667 46.933333 0 213.333333 170.666667 384 384 384s384-170.666667 384-384-170.666667-384-384-384c-25.6 0-46.933333 4.266667-72.533333 8.533333-12.8 0-21.333333-4.266667-25.6-17.066666 0-12.8 4.266667-21.333333 17.066666-25.6 25.6-4.266667 51.2-8.533333 81.066667-8.533334 234.666667 0 426.666667 192 426.666667 426.666667s-192 426.666667-426.666667 426.666667z" fill="#dbdbdb" p-id="2694"></path><path d="M119.466667 418.133333h-8.533334c-8.533333-4.266667-17.066667-17.066667-12.8-29.866666 42.666667-119.466667 128-213.333333 238.933334-256 12.8-4.266667 21.333333 0 25.6 12.8 4.266667 12.8 0 21.333333-12.8 25.6C256 213.333333 174.933333 298.666667 140.8 405.333333c-4.266667 8.533333-12.8 12.8-21.333333 12.8z" fill="#dbdbdb" p-id="2695"></path><path d="M392.533333 657.066667c-4.266667 0-12.8 0-17.066666-4.266667-8.533333-8.533333-8.533333-21.333333 0-29.866667l213.333333-213.333333c8.533333-8.533333 21.333333-8.533333 29.866667 0s8.533333 21.333333 0 29.866667l-213.333334 213.333333c0 4.266667-8.533333 4.266667-12.8 4.266667z" fill="#dbdbdb" p-id="2696"></path><path d="M605.866667 657.066667c-4.266667 0-12.8 0-17.066667-4.266667l-213.333333-213.333333c-8.533333-8.533333-8.533333-21.333333 0-29.866667s21.333333-8.533333 29.866666 0l213.333334 213.333333c8.533333 8.533333 8.533333 21.333333 0 29.866667 0 4.266667-8.533333 4.266667-12.8 4.266667z" fill="#dbdbdb" p-id="2697"></path></svg></div>
    return (
        <>
            {props.renderControl ? props.renderControl({ open: () => { setShow(true) } }) : ''}
            {show && ReactDOM.createPortal(

                <ModalContainer >
                    <ModalOverlay ref={overlayRef} className={`animate__animated animate__fadeIn`}>
                        <ModalBox ref={boxRef} className={`modalBox animate__animated ${props.animateInClass}`}>
                            {CloseIcon}
                            {props.children({ close: handleClose })}
                        </ModalBox>
                    </ModalOverlay>
                </ModalContainer>

                , document.getElementById('root'))
            }
        </>
    )
}