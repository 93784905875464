import styled from 'styled-components'


export const Tag = styled.div`
  border-radius: 20px;
  cursor: pointer;
  background: #eeeeee;
  padding: 0.4rem 1.3rem;
  display: inline-block;
  margin-right: 1.4rem;
  color: #597073;
  margin-bottom: 1rem;
  flex-shrink: 0;
  &.tagActive{
      background-color: #357ebd;
      color: #ffffff;
  }
`  
export const TagGroup = styled.div`
   display: flex;
   align-items: flex-start;
   flex-wrap: wrap;
   
`  
export const AddIcon = styled.div`
   background-color: aliceblue;
   width: 3rem;
   text-align: center;
   padding: 0.3rem 0;
   border-radius: 50%;
   cursor: pointer;
   flex-shrink: 0;
`  
export const RemoveIcon = styled.div`
   background-color: #ebdede;
   width: 3rem;
   text-align: center;
   padding: 0.3rem 0;
   border-radius: 50%;
   cursor: pointer;
   flex-shrink: 0;
`  
export const AddButton = styled.div`
   width: 3.6rem;
  font-size: 1.3rem;
  margin-left: 0.6rem;
  /* margin-top: 4rem; */
`  