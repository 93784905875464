import { EditorContainer } from "./TextEditor.style";
import { useRef, useEffect } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";

const quillConfig = {
  theme: "snow",
  modules: {
    toolbar: [
      ["bold", "code-block"],
      [{ header: [2, 3, 4, false] }],
      [{ color: [] }],
    ],
  },
  placeholder: "Compose an epic...",
};

const TextEditor = () => {
  const editorRef = useRef();
  const isMounted = useRef(false);

  useEffect(() => {
    const handleContentsChange = () => {
      console.log("changed");
    };
    if (!isMounted.current) {
      var quill = new Quill(editorRef.current, quillConfig);
      isMounted.current = true;
      quill.on("text-change", handleContentsChange);
    }
    return () => {
      if (quill) {
        quill.off("text-change", handleContentsChange);
        quill = null;
      }
    };
  }, []);
  return (
    <EditorContainer>
      <div ref={editorRef}></div>
    </EditorContainer>
  );
};

export default TextEditor;
