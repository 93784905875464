import styled from 'styled-components'

export const BookmarkBox = styled.div`
  margin-top: 2rem;
  border-radius: 4px;
  overflow: hidden;
  background-color: #fbfbfb;
  height: 49.4rem;

`
export const NavPannel = styled.div`
background-color: rgb(240,240,240);
padding: 0.8rem 1rem;
font-size: 1.3rem;
color: #7f7f7f;
display: flex;
align-items: center;
 .Bookmark{
    width: 5rem;
    height: 5rem;
    margin-left: 1rem;
    margin-right: 8.5rem;
    @media screen and (max-width: 1200px) {
        margin-right: 2rem;
    }

 }
`

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 15px;
  margin-top: 2rem;
`
export const Item = styled.a`
text-decoration: none;
width: 18%;
border-radius: 6px;
margin-top: 15px;
padding: 12px 15px;
background-color: #ffffff;
cursor: pointer;

.title{
    font-size: 1.5rem;
    color: #e17055;
    white-space: nowrap;
    overflow: hidden;
    
}
.description{
    font-size: 1.2rem;
    color: #7f7f7f;
    /* color: #a5a5a5; */
    margin-top: 0.6rem;
}
&:not(:nth-child(5n)){
    margin-right: calc(10%/4);
}
&:hover{
    background-color: #F0F8FF;
    .title{
        color: #5649b8;
    }
    .description{
        color: rgba(97,82,207,0.5);
    }
}

`