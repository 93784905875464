import styled from 'styled-components'


export const SearchBoxContainer = styled.div`
   border: 1px #eeeeee solid;
   border-radius: 50px;
   padding: 3px;
   background: #ffffff;
   padding:4px 10px;
   padding-left: 18px;
    input{
        border: none;
        outline: none;
        width: 18rem;
        margin-right: 10px;
    }
`