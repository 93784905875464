import styled from 'styled-components'

export const ArchivesContent = styled.div`
  display: flex;
  margin: auto;
  padding: 0 5rem;
  max-width: 1444px;
  @media screen and (max-width: 1350px) {
     padding: 0 2rem;
  }
`;

export const ArchivesContentLeft = styled.div`
  flex: 1;
  margin-top: 2rem;
  margin-right: 3rem;
  @media screen and (max-width: 1350px) {
    margin-right: 0rem;
  }
`;

export const ArchivesContenRight = styled.div`
  width: 43rem;
  border-left: rgb(230 230 230) 1px solid;
  margin-left: 3rem;
  padding: 4rem 3.5rem;
  @media screen and (max-width: 950px) {
    position: fixed;
    right: 0;
    background: #ffffff;
    min-height: 100vh;
    z-index: 200;
    top: 0;
  }
  @media screen and (max-width: 550px) {
     width: 100%;

  }
`;
export const Hr = styled.div`
height: 1px;
box-shadow: rgb(230 230 230) 0px -1px 0px inset;
`;
export const FilterButton = styled.div`
  /* width: 5rem; */
  width: 5rem;
  font-size: 13px;
`;
export const GreyCover = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 115;
  background: rgba(0,0,0,0.4);
`;