import React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import Login from "./routes/Logon/Login/Login.component";
import Home from "./routes/Home/Home";
import Manage from "./routes/Manage/Manage";
import "animate.css";
import "antd/dist/reset.css";
import { Admin, Profile, Userlist, Editor } from "./routes/Home/routes";
import Authenticate from "./utils/Authenticate.component";
import Blog from "./routes/Blog/Blog";
import Layout from "./routes/Layout/Layout";
import Archive from "./routes/Archive/Archive";
import NoteEverything from "./routes/NoteEverything";
import ChatGPT from "./routes/ChatGPT/ChatGPT";
import WriteArticle from "./routes/Manage/Article/WriteArticle";
import BlogDetail from "./routes/Blog/BlogDetail";

enum Roles {
  admin = 100,
  editor = 101,
  user = 102,
}
function App() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      {/* <Route path='/' element={<Home />} /> */}
      <Route path="/" element={<Layout />}>
        <Route path="" element={<Home />} />
        <Route path='blog' element={<Blog />} />
        <Route path='archive' element={<Archive />} />
        <Route path='note-everything' element={<NoteEverything />} />
        <Route path='chatgpt-use' element={<ChatGPT />} />
        <Route path='blog-detail' element={<BlogDetail />} />
      </Route>
      <Route path="/blog" element={<Blog />} />
      <Route path="/manage" element={<Manage />}>
        <Route path="profile" element={<Profile />} />
        <Route
          path="admin"
          element={<Authenticate allowedRole={[Roles.admin]} />}
        >
          <Route path="" element={<Admin />} />
        </Route>
        <Route path="userlist" element={<Userlist />} />
        <Route path="editor" element={<Editor />} />
        <Route path="article-write" element={<WriteArticle />} />
      </Route>
    </Routes>
  );
}

export default App;
