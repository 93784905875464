import {
  ArchivesContent,
  ArchivesContenRight,
  ArchivesContentLeft,
  Hr,
  FilterButton,
  GreyCover
} from "./Archive.style";
import Series from "../../components/Series/Series";
import Tags from "../../components/Tags/Tags";
import Years from "../../components/Years/Years";
import ArchiveBlogs from "./ArchiveBlogs";
import axios from 'axios'
import { url_config } from "../../url_config";
import { useEffect, useState } from "react";
import { Pagination } from 'antd';
import { useLocation } from "react-router-dom";
import useWindowSize from "../../hooks/useWindowSize";
import Button from "../../components/button/button.component";

const Archive = () => {
  const [articles, setArticles] = useState<any>({})
  const [count, setCount] = useState<number>(1)
  const [page, setPage] = useState<number>(1)
  const [type, setType] = useState<string>('')
  const [showFilter, setShowFilter] = useState<boolean>(false)
  const [typeId, setTypeId] = useState<number | string>(1)
  const location = useLocation();

  const { width } = useWindowSize()

  useEffect(() => {
    if (location.state?.id && location.state?.type) {
      setType(location.state.type)
      setTypeId(location.state.id)
    } else {
      setType('all')
    }
    setPage(1)
    
    if (width && width<950){
      setShowFilter(false)
    }
  }, [location.state,width])
  
  useEffect(() => {
    if (width && width < 950 && showFilter) {
      setShowFilter(false)
    }
    if (width && width > 950 && !showFilter) {
      setShowFilter(true)
    }

  }, [width])
  const dealArticle = (article: any) => {
    article.forEach((i: any) => {
      i.briefImg = url_config.base_url + '/media' + i.briefImg.split('media')[1]
      i.DetailImg = url_config.base_url + '/media' + i.DetailImg.split('media')[1]
    })
    return article
  }

  useEffect(() => {
    if (type && type !== 'all') {
      const data = {
        sortBy: type,
        condition: typeId
      }
      axios.post(url_config.sortByCondition + `?page=${page}`, data).then(res => {
        if (res.status === 200) {
          setArticles(dealArticle(res.data.results))
          setCount(res.data.count)
        }
      })
    }
    if (type && type === 'all') {
      axios.get(url_config.articles + `?page=${page}`).then(res => {
        if (res.status === 200) {
          setArticles(dealArticle(res.data.results))
          setCount(res.data.count)
        }
      })
    }

  }, [page, type, typeId])
  const onChange = (page: number) => {
     setPage(page)
  }
  return (
    <ArchivesContent>
      { showFilter && width &&  width <950 && <GreyCover onClick={() => { setShowFilter(false) }}></GreyCover>}
      <ArchivesContentLeft>
        {width &&  width  <950 && <FilterButton >
           <Button buttonType='animateOut' onClick={() => { setShowFilter(true) }} buttonText='filter' height='2.5rem' textAlign='center'>
           </Button>
        </FilterButton>}
        
        <ArchiveBlogs article={articles} />
        <Pagination defaultPageSize={12} defaultCurrent={1} onChange={onChange} showSizeChanger={false} showQuickJumper={count > 75} total={count} />
      </ArchivesContentLeft>
      
      {showFilter && <ArchivesContenRight>
        <Series activeTag={type === "series" ? typeId : -1} />
        <Hr />
        <Tags activeTag={type === "tag" ? typeId : -1} />
        <Hr />
        <Years activeTag={type === "year" ? typeId : '0000'} />
      </ArchivesContenRight>}

    </ArchivesContent>
  );
};

export default Archive;
