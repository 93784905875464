import { useField } from 'formik';
import {Field,Input,Error,InputBox} from './Input.style'


const FormInput = ({label, ...props})=>{
    const [field, meta] = useField(props)
    return (
        <InputBox>
           <Field htmlFor={props.id || props.name }>{ label }</Field>
           <Input {...field} {...props}></Input>
           {meta.touched && meta.error ? (
             <Error >{meta.error}</Error>
           ): null}
        </InputBox>
    )
}
export default FormInput