import { YearsBox, Year } from "./Years.style"
import { useNavigate } from "react-router-dom"
import { useState,useEffect } from "react"

const years = ['2023', '2022', '2021', '2020', '2019']

interface Props {
    activeTag?: any
}


const Years: React.FC<Props> = (props) => {
    const [active, setActive] = useState<string>('')
    const navigate = useNavigate()
    const handleNavi = (id: string) => {
        setActive(id)
        navigate('/archive', {
            state: {
                id: id,
                type: 'year'
            }
        })
    }
    useEffect(() => {
        if (props.activeTag) {
            setActive(props.activeTag)
        }
    }, [props.activeTag])
    return (
        <YearsBox>
            {years.map((item, index) => <Year onClick={() => handleNavi(item)} key={index} active={active === item}>{item}</Year>)}
        </YearsBox>
    )
}

export default Years