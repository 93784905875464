import styled from "styled-components";

export const RecentBox = styled.div`
  margin:2rem 0;
`;
export const RecentTitle = styled.div`
  font-weight: bold;
`;
export const RecentItem = styled.div`
   margin: 2rem 0;
   cursor: pointer;
`;
export const RecentItemTitle = styled.div`
  font-weight: bold;
`;
export const VisitTime = styled.div`
  
`;
export const ReadTime = styled.div`
  font-weight: bold;
`;
export const Briefs = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.8rem;
  font-size: 14px;
  color: rgba(117, 117, 117, 1);
  .dot{
    margin: 0 1.5rem;
    position: relative;
    bottom: 4px;
  }
`;

