
import { Outlet } from "react-router-dom"
import useUserinfo  from '../hooks/useGetUserinfo'
import { Navigate } from "react-router-dom";

type AppProps = {
    allowedRole: number[]
}

export default function Authenticate(props:AppProps){
    const userinfo = useUserinfo()
    const { allowedRole } = props
    let ret = <Navigate to='/login' replace={true} />
    if (userinfo.roles && userinfo.roles.some(e=>allowedRole.includes(e))){
        ret = <Outlet/>
    }
    return <div>{ ret }</div>

}