import styled from 'styled-components'

export const FocusBox = styled.div`  
  border: 1px #bebebe solid;
  padding: 2.5rem;
  border-radius: 4px;
  position: relative;
  padding-bottom: 8rem;
  @media screen and (max-width: 1000px) {
        margin-left: 0;
        border: none;
        padding-top: 0;
        flex: 1;
  }
  @media screen and (max-width: 720px) {
        border-right: none;
  }
  .icon{
      width: 3rem;
      height: 3rem;
  }
  
  .focus-list{
      font-size: 1.3rem;
      margin: 15px 0;
      display: flex;
      align-items: center;
      margin-right: 7rem;
  }
  .finished{
    display: block;
  }
  .ongoing{
      width: 1.4rem;
      height: 1.4rem;
      background-color: #d6d6d6;
      border-radius: 50%;
      display: inline-block;
      margin-right: 2rem;
      flex-shrink: 0;
  }
  .orange0{
      color: #f63;
      margin-right: 2rem;
     
  }
`
export const FocusListItem = styled.div`
   @media screen and (max-width: 1000px) {
       padding-left: 2rem;
       max-height: 250px;
       overflow: auto;
   }
`
export const SvgBg = styled.div`
      background: #335dff;
      display: inline-block;
      padding: 7px;
      padding-bottom: 3px;
      border-radius: 4px;
      text-align: center;
`

export const TitleGroup = styled.div`
   text-align: center;
   @media screen and (max-width: 1000px) {
       display: flex;
       text-align: left;
       align-items: center;
       .icon{
         width: 2rem;
         height: 2rem;
       }
       ${SvgBg}{
         display: block;
         padding: 5px;
         padding-bottom: 2px;
         margin-right: 1.5rem;
       }
   }
`


export const Title = styled.h3`
   font-weight: bold;
   /* margin-top: 1rem; */
   margin: 1.5rem 0;
   font-family: 'Roboto', sans-serif;
   text-align: center;
`
export const Description = styled.p`
  font-size: 1.4rem;
  margin-top: 1rem;
  
`
export const RemainTime = styled.div`
  position: absolute;
  right: 2rem;
  bottom: 1rem;
  display: flex;
  font-size: 1.4rem;
  /* span{
    margin-right: 1rem;
    position: relative;
    bottom: 2.5rem;
  } */
  .countDown{
    margin-right: 1rem;
  }
  .ant-statistic-content{
    color: #fa6330;
    font-size: 13px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
  .clock-icon{
      height: 8rem;
      /* transform: rotate(-90deg); */
  }
`