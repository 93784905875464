// export const base_url = 'http://127.0.0.1:8000'
// export const base_url = 'http://go-up.top:3000'
export const base_url = 'http://go-up.top:8010'

export const url_config ={
    base_url,
    getAccessWithRefresh: '/api/token/refresh/',
    imgUpload: base_url+'/image/upload',
    imgUploadTo: base_url+'/image/uploadImg',
    getTags: base_url + '/article/tags/',
    getSeries: base_url + '/article/series/',
    articles: base_url + '/article/articles/',
    sortByCondition: base_url + '/article/general_lookup',
    getTagsWithCount: base_url + '/article/tags-count/',
    filterByTag: base_url + '/bookmark/filterByTag',
    bookmark: base_url + '/bookmark/items/',
    bookmarkTag: base_url + "/bookmark/tags/"
}