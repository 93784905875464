import { useEffect, useState } from "react";
import axios from 'axios'
// import UploadComponent from "../../components/Upload/Upload";

const ChatGPT = ()=>{
    const [chat, setChat] = useState('')
    const handleChange = (e:any)=>{
       setChat(e.target.value)
    }
    const handleClick = ()=>{
        if (chat){
           axios.get('http://127.0.0.1:8000/chat/getChatgptRes').then(res=>{
               console.log('return', res)
           })
        }else{
            alert('请输入')
        }
    }
    useEffect(()=>{
        console.log('how times')
    },[])
    return (
        <div>
            <textarea value={chat} onChange={handleChange}></textarea>
            <button onClick={handleClick}>提交</button>
            <div>chatGPT返回</div>
            {/* <UploadComponent /> */}
        </div>
    );
  
}

export default ChatGPT