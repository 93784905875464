import styled, {css} from "styled-components";

export const SeriesBox = styled.div`
  margin-bottom: 3rem;
`

const activeStatus = css`
  color: #10b0e6;

`
export const SeriesTitle = styled.div`
   font-weight: bold;
   margin-bottom: 2rem;
   
`
export const BlogTitle = styled.div`
   margin: 1rem 2.5rem;
   font-size: 14px;
   cursor: pointer;
   ${({active})=> active && activeStatus}
`