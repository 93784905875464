import { useEffect, useState } from "react"

const usePageBottom = ()=>{
    const [ isBottom, setIsBottom] = useState(false)
    useEffect(()=>{
        const handleScroll = ()=>{
            if ((window.innerHeight + document.documentElement.scrollTop) 
                >= document.documentElement.scrollHeight){
                
                if (!isBottom){
                    setIsBottom(true)
                }
            }else{
                if (isBottom){
                    setIsBottom(false)
                }
            }
        }
        window.onscroll = handleScroll
    },[isBottom])
    return isBottom
}

export default usePageBottom