import styled from "styled-components";

export const BlogContent = styled.div`
  display: flex;
  margin: auto;
  padding: 0 5rem;
  max-width: 1444px;
  @media screen and (max-width: 900px) {
    padding: 0 2rem;
  }
`;
export const BlogContentLeft = styled.div`
  flex: 1;
  margin-top: 2rem;
  margin-right: 6rem;
  overflow: hidden;
  @media screen and (max-width: 1400px) {
        margin-right: 3rem;
  }
  @media screen and (max-width: 1060px) {
        margin-right: 1rem;
        padding: 0 1rem;
        padding-left: 1rem;
  }
  
`;

export const BlogContenRight = styled.div`
  width: 43rem;
  border-left: rgb(230 230 230) 1px solid;
  margin-left: 3rem;
  padding: 4rem 3.5rem;
  @media screen and (max-width: 1220px) {
        width: 35rem;
        padding: 3rem;
  }
  @media screen and (max-width: 1000px) {
        width: 30rem;
        padding: 2rem;
  }
`;
export const BriefPannel = styled.div`
  margin-top: 3rem;
  margin-bottom: 4rem;
  /* border: 1px red solid; */
  /* border-bottom: 1px ; */
  box-shadow: rgb(230 230 230) 0px -1px 0px inset;
  display: flex;
  align-items: center;
  position: relative;
  @media screen and (max-width: 700px) {
        margin-bottom: 0;
  }
  .addIcon {
    width: 2rem;
    height: 2rem;
    margin-bottom: 1.2rem;
    cursor: pointer;
  }

  .Archive{
    position: absolute;
    right: 0;
    color: rgba(41, 41, 41, 0.5);
    font-size: 13px;
    bottom: 10px;
    cursor: pointer;
  }
`;


export const Hr = styled.div`
height: 1px;
box-shadow: rgb(230 230 230) 0px -1px 0px inset;
`;
