import styled from 'styled-components'

export const NotesContent = styled.div`
  padding-top: 4rem;
`;
export const RecentTitle = styled.div`
  font-weight: bold;
  margin-top: 2rem;
`;
export const NotesContainer = styled.div`
   display: flex;
   flex-wrap: wrap;
`;