import { Blog } from "./ArchiveBlogs.style"
import useStorageArticle from "../../hooks/useStorageArticle";




interface Props {
    article: any,
}

const ArchiveBlogs: React.FC<Props> = ({ article }) => {
    
    const {StoreArticle} = useStorageArticle()
    return (
        <Blog>
            <div className="blog-box" >
                <div className="blogs">
                    {article.length && article.map((item: any) => (
                        <div className="each" key={item.id} onClick={()=>{ StoreArticle(item)}}>
                            <img className="blog-img" src={item.briefImg} alt="" />
                            <div className="blog-title">
                                {item.title}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </Blog>
    )
}

export default ArchiveBlogs