
import { useEffect, useState } from "react"
import axios from 'axios'
import { url_config } from '../url_config'

interface Props {
    sortBy: string,
    condition?: string | number,
    page?: number
}
function useGetArticles<T>(props: Props) {
    const { sortBy } = props
    const [articles, setArticles] = useState<T | []>([])
    useEffect(() => {
        let method = 'post'
        let data: Props = { sortBy,condition: props.condition?props.condition: ''}
        axios({
            method: method,
            url: props.page ? url_config.sortByCondition+`?page=${props.page}`: url_config.sortByCondition,
            data: data
        }).then(res => {
            if (res.status === 200) {
                setArticles(res.data)
            }
        })
    }, [sortBy,props.condition,props.page])
    if (!Array.isArray(articles) || !articles.length) {
        return [];
    }

    return articles
}
export default useGetArticles