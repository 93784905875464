import styled from 'styled-components'

export const Navbar = styled.div`
  display: flex;
`
export const Navitem = styled.div`
  margin: 0 1rem;
  font-size: 1.4rem;
  border-radius: 4px;
  flex-shrink: 0;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  :not(:first-child){
      margin-left: 3rem;
  }
`
export const NoteNav = styled.div`
padding-bottom: 2rem;
  .active{
   color: #ffffff;
   background-color:rgb(139,128,222);
  }
`
export const BookMarkNav = styled.div`
  .active{
    background: linear-gradient(to right,#74b9ff,#6c5ce7);
    color: #ffffff;
    border-radius: 40px;
  }
  @media screen and (max-width: 1200px) {
    ${Navitem}{
        :not(:first-child){
           margin-left: 1rem;
       }
      }
  }
`
export const ArticleListNav = styled.div`
   margin-bottom: 1.5rem;
   ${Navitem}{
    flex: 1;
    text-align: center;
     padding: 1.5rem 0;
    margin-top: 1.2rem;
    }
  .active{
    position: relative;
    &::after{
        content: '';
        position: absolute;
        width: 4.5rem;
        bottom: 0;
        height: 3px;
        border-radius: 4px;
        background-color: #335dff;
        left: 50%;
        transform: translateX(-50%);
    }
    
  }
  @media screen and (max-width: 1000px) {
    
    ${Navitem}{
        
      }
  }
`
export const BlogNav = styled.div`
${Navitem}{
    margin-left: 2rem;
    padding-bottom: 1.2rem;
  }
  ${Navitem}:nth-child(2){
      margin-left: 1rem;
  }
  .active{
   position: relative;
    &::after{
        content: '';
        position: absolute;
        width: 4rem;
        bottom: 0;
        height: 1px;
        border-radius: 4px;
        background-color: rgba(41, 41, 41, 1);
        left: 50%;
        transform: translateX(-50%);
    }
  }
`
