import styled from 'styled-components'

export const Blog = styled.div`
  .blog-box{
    min-height: 58rem;
    margin-bottom: 3rem;
  }
  .blogs{
    display: flex;
    flex-wrap: wrap;
  }
.each{
    width: 24%;
    cursor: pointer;
    margin-top: 2.5rem;
   &:not(:nth-child(4n)){
     margin-right: calc(4%/3)
   }
   @media screen and (max-width: 1350px) {
     width: 32%;
    &:not(:nth-child(4n)){
     margin-right: 0;
     }
     &:not(:nth-child(3n)){
      margin-right: calc(4%/2)
     }
  }
  @media screen and (max-width: 550px) {
     width: 48%;
    &:not(:nth-child(3n)){
     margin-right: 0;
     }
     &:not(:nth-child(2n)){
      margin-right: 4%;
     }
  }
}
.blog-img{
  height: 12rem;
  width: 100%;
  object-fit: cover;
}
.blog-title{
    font-size: 1.4rem;
    margin-top: 1.8rem;
    font-weight: bold;
    &:hover{
        color: rgba(6,147,227,1);
    }
}
.blog-des{
    font-size: 13px;
    color: #333e63;
    opacity: 0.5;
    margin-top: 1rem;
}
`;