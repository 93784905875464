import styled from 'styled-components'

export const FooterBox = styled.div`
 background-color: rgb(30,30,30);
 /* color: rgba(255,255,255,.5); */
 /* color: white; */
 text-align: center;
 padding: 2rem 0 1rem 0;
 margin-top: 0.2rem;
 font-size: 1.4rem;
 z-index: 100;
 position: relative;
 a{
    text-decoration: none;
    color: white;
 }
`