import { Tag, AddIcon, TagGroup, RemoveIcon, AddButton } from './TagGrouo.style'
import { Input, message } from 'antd';
import { useState, useEffect } from 'react'
import Button from "../../../components/button/button.component"
import axios from 'axios'
import { url_config } from "../../../url_config"


export interface TagInterface {
    id: number;
    name: string;
}

interface Props {
    handleSelectTag: Function,
    defaultSelect: number[] | []
}

const TagGroups: React.FC<Props> = ({ handleSelectTag, defaultSelect }) => {
    const [tagList, setTagList] = useState<TagInterface[]>([]);
    const [newTag, setNewTag] = useState('')
    const [showAddTag, setShowAddTag] = useState(false)
    const [selectedTag, setSelectedTag] = useState<number[]>([])
    useEffect(() => {
        axios.get(url_config.getTags).then(res => {
            if (res.status === 200) {
                setTagList(res.data)
            }
        })

    }, [])
    useEffect(() => {
        if (defaultSelect.length) {
            setSelectedTag(defaultSelect)
        }
    }, [defaultSelect])
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewTag(e.target.value)
    };
    const handleRemove = () => {
        setShowAddTag(false)
        setNewTag('')
    }
    const handleSubmit = () => {
        if (newTag) {
            axios.post(url_config.getTags, { name: newTag }).then(res => {
                if (res.status === 201) {
                    setTagList([...tagList, res.data])
                    setShowAddTag(false)
                    setNewTag('')
                }
            })
        } else {
            message.warning('请填写')
        }
    }
    const handleSelect = (id: number) => {
        setSelectedTag(() => {
            if (selectedTag.includes(id)) {
                const newTags = selectedTag.filter(i => i !== id)
                handleSelectTag(newTags)
                return newTags
            } else {
                handleSelectTag([...selectedTag, id])
                return [...selectedTag, id]
            }
        })

    }


    return (
        <TagGroup>
            {tagList.length > 0 && tagList.map(item => <Tag className={selectedTag.includes(item?.id) ? 'tagActive' : ''} onClick={() => handleSelect(item?.id)} key={item!.id}>{item!.name}</Tag>)}
            {!showAddTag && <AddIcon onClick={() => setShowAddTag(true)}>+</AddIcon>}
            {showAddTag ? <>
                <RemoveIcon onClick={handleRemove}>-</RemoveIcon>
                <Input value={newTag} style={{ width: 130, marginLeft: '0.6rem' }} placeholder="" allowClear onChange={onChange} />
                <AddButton >
                    <Button buttonType='regular' onClick={handleSubmit} buttonText='add' height='3rem' textAlign='center'>
                    </Button>
                </AddButton>
            </> : ''
            }
        </TagGroup>
    )
}

export default TagGroups