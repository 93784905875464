import {configureStore} from '@reduxjs/toolkit'
import todosReducer from '../stateSlice/todosSlice'
import { combineReducers } from "@reduxjs/toolkit";
import userDetail from '../stateSlice/userDetail';


const rootReducer = combineReducers({
    todos: todosReducer,
    userDetails: userDetail

  })


const store= configureStore({
    reducer: rootReducer
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store