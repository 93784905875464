import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import {
  ContainerOutlined,
  DesktopOutlined,
  PieChartOutlined,
  NotificationOutlined
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Layout,  Menu } from 'antd';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const { Sider } = Layout;

const items: MenuItem[] = [
  getItem('Profile', 'profile', <PieChartOutlined />),
  getItem('userlist', 'userlist', <DesktopOutlined />),
  getItem('文章管理', 'article', <ContainerOutlined />,[
    getItem('文章','articlelist'),
    getItem('文章发布','article-write'),
  ]),
  getItem('Admin', 'admin', <NotificationOutlined />),
];

const Manage: React.FC = () => {
  const navigate = useNavigate()
  const onClick: MenuProps['onClick'] = (e) => {
    navigate(e.key)
  };

  return (
    <Layout hasSider>
      <Sider
        collapsible
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,

        }}
      >
        <Menu
          defaultSelectedKeys={['1']}
          defaultOpenKeys={['sub1']}
          mode="inline"
          theme="dark"
          items={items}
          onClick={onClick}
        />
      </Sider>
      <div style={{ marginLeft: "250px",height: '100vh',paddingTop: '20px'}}>
        <Outlet />
      </div>

    </Layout>
  );
};

export default Manage;