import logo from "../../assets/logo-pure.png";
import SearchBox from "../../components/SearchBox/SearchBox.component";
import Footer from "../../components/Footer/Footer";
import Button from "../../components/button/button.component";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  Header,
  HeaderLeft,
  HeaderRight,
  NavItem,
  Container,
  LoginBtn,
  Main,
  HeaderBox,
  NavItemTemp
} from "./Layout.style";
import { Outlet } from "react-router-dom";
import useWindowSize from "../../hooks/useWindowSize";


const Layout = () => {
  const {width} = useWindowSize()
  const navigate = useNavigate()

  return (
    <Container>
      <Header>
        <HeaderBox>
          <HeaderLeft>
            <NavLink to={"/"}>
              <img src={logo} alt="logo" />
            </NavLink>
            <NavItem to="/blog">Blog</NavItem>
            {/* <NavItem to="/note-everything">随手记</NavItem> */}
            <NavItemTemp onClick={()=>{ navigate('/login')}}>随手记</NavItemTemp>
            {/* <NavItem to="/chatgpt-use">chatGPT</NavItem> */}
          </HeaderLeft>
          <HeaderRight>
            {width && width > 790 && <SearchBox />}
            <LoginBtn>
              <Button
                buttonType="regular"
                buttonText="登录"
                height={width && width >790 ?`3.8rem` :'3.2rem'}
                textAlign="center"
                onClick={()=>{ navigate('/login')}}
              ></Button>
            </LoginBtn>
          </HeaderRight>
        </HeaderBox>
      </Header>
      <Main>
        <Outlet />
      </Main>
      <Footer />
    </Container>
  );
};

export default Layout;
