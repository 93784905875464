import { UploadOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { Button, message, Upload } from 'antd';
import { url_config } from '../../url_config';
import type { UploadFile } from 'antd/es/upload/interface';

interface Props {
  onFileChange: Function,
  defaultImgs: any
}
const uploadTo = url_config.imgUpload



const UploadComponent:React.FC<Props> = ({onFileChange,defaultImgs}) => {
  const props: UploadProps = {
    name: 'image',
    action: uploadTo,
    listType:"picture",
    maxCount:1,
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        let filtered = []
        if (info.fileList.length>0){
           filtered = info.fileList.map(i=> i.response)
        }
        onFileChange(filtered)
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
  
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const defaultImgsList  = defaultImgs.map((item:any,index:number)=>{
    return {
      uid: index,
      name: 'index-'+(index+1),
      status: 'done',
      url:  item.image,
      thumbUrl: item.image,
    }
  })
  return (
    <Upload {...props} defaultFileList={[...defaultImgsList]} >
      <Button icon={<UploadOutlined />}>点击上传</Button>
    </Upload>
  )
}
export default UploadComponent