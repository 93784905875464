import { FooterBox } from "./Footer.style"

const Footer = ()=>{
    return (
        <FooterBox>
            <a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">粤ICP备2021174190号-1</a>
        </FooterBox>
    )
}

export default Footer