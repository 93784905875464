import styled, {css} from "styled-components";

export const TagsBox = styled.div`
   margin: 2rem 0;
   margin-bottom: 3.5rem;
`
const activeStatus = css`
  background: #43bfe8;
  color: #ffffff;
`
export const Tag = styled.div`
background-color: rgba(242, 242, 242, 1);
padding: 9px 15px;
border-radius: 50px;
font-size: 13px;
display: inline-block;
margin-left: 1rem;
margin-top: 1.5rem;
cursor: pointer;
${({active})=> active && activeStatus}
span{
    font-size: 12px;
    display: inline-block;
    transform: scale(0.9);
    position: relative;
    left: -4px;
    bottom: -2px;
}
`

export const TagTitle = styled.div`
  font-weight: bold;
  margin-bottom: 1rem;
  
`
export const TagGroup= styled.div`
  
`