import { NoteBox,NoteTime,NoteContent } from "./Note.style"

interface Props extends React.HTMLProps<HTMLDivElement>{
   time: string,
   content: string
}

const Note:React.FC<Props> = ({time,content})=>{
    return (
        <NoteBox>
            <NoteTime>{time}</NoteTime>
            <NoteContent>
                {content}
            </NoteContent>
        </NoteBox>
    )
}

export default Note