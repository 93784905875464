import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { url_config } from "../../url_config";
import { useEffect, useState } from "react";
// import Highlight, { defaultProps } from "prism-react-renderer";
import { Highlight, themes } from "prism-react-renderer";
import {
  BlogDetailBox,
  NavByTag,
  BlogContent,
  BlogTitle,
  BlogTime,
  BlogReadTime,
  BlogDescription,
  NavTag,
  Tag,
} from "./BlogDetail.style";
import parse, { domToReact } from "html-react-parser";
// import oceanicNext from 'prism-react-renderer/themes/oceanicNext';
import { CopyToClipboard } from "react-copy-to-clipboard";
import useStorageArticle from "../../hooks/useStorageArticle";


// const options = { indent_size: 2, space_in_empty_paren: true }

const BlogDetail = () => {
  const [article, setArticle] = useState({});
  const [copied, setCopied] = useState(false);
  const [searchParams] = useSearchParams();
  const [language, setLanguage] = useState("javascript");
  const id = searchParams.get("id");
  const [activeTag, setActiveTag] = useState("");

  const { getArticle } = useStorageArticle();
  const articles = getArticle();
  useEffect(() => {
    function retriveData() {
      axios.get(url_config.articles + id + "/").then((res) => {
        if (res.status === 200) {
          setArticle(res.data);
        }
      });
    }

    if (articles && articles.id == id) {
      setArticle(articles);
    } else {
      retriveData();
    }
    axios.post(url_config.articles + id + "/increment_visits/").then((res) => {
      if (res.status === 200) {
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  let renderedHtml = null;

  const h2h3List = [];
  if (article.content) {
    const html = article.content;
    let id = 0;
    const options = {
      replace: ({ children, name, attribs }) => {
        if (name === "pre" && children[0].children.length) {
          // const parsed = js_beautify(children[0].children[0].data,options)
          const parsed = children[0].children[0].data;
          // console.log('parsed',parsed)
          return (
            <div>
              <Highlight
                theme={themes.jettwaveLight}
                code={parsed}
                language={language}
              >
                {({
                  className,
                  style,
                  tokens,
                  getLineProps,
                  getTokenProps,
                }) => (
                  <pre className={className} style={style}>
                    {tokens.map((line, i) => (
                      <div {...getLineProps({ line, key: i })}>
                        {line.map((token, key) => (
                          <span {...getTokenProps({ token, key })} />
                        ))}
                      </div>
                    ))}
                    <CopyToClipboard
                      text={children[0].children[0].data}
                      onCopy={() => setCopied(true)}
                    >
                      <svg
                        t="1687453057993"
                        className="copy-icon"
                        viewBox="0 0 1024 1024"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        p-id="1450"
                        width="200"
                        height="200"
                      >
                        <path
                          d="M853.333333 981.333333h-384c-72.533333 0-128-55.466667-128-128v-384c0-72.533333 55.466667-128 128-128h384c72.533333 0 128 55.466667 128 128v384c0 72.533333-55.466667 128-128 128z m-384-554.666666c-25.6 0-42.666667 17.066667-42.666666 42.666666v384c0 25.6 17.066667 42.666667 42.666666 42.666667h384c25.6 0 42.666667-17.066667 42.666667-42.666667v-384c0-25.6-17.066667-42.666667-42.666667-42.666666h-384z"
                          p-id="1451"
                          fill="#cdcdcd"
                        ></path>
                        <path
                          d="M213.333333 682.666667H170.666667c-72.533333 0-128-55.466667-128-128V170.666667c0-72.533333 55.466667-128 128-128h384c72.533333 0 128 55.466667 128 128v42.666666c0 25.6-17.066667 42.666667-42.666667 42.666667s-42.666667-17.066667-42.666667-42.666667V170.666667c0-25.6-17.066667-42.666667-42.666666-42.666667H170.666667c-25.6 0-42.666667 17.066667-42.666667 42.666667v384c0 25.6 17.066667 42.666667 42.666667 42.666666h42.666666c25.6 0 42.666667 17.066667 42.666667 42.666667s-17.066667 42.666667-42.666667 42.666667z"
                          p-id="1452"
                          fill="#cdcdcd"
                        ></path>
                      </svg>
                    </CopyToClipboard>
                  </pre>
                )}
              </Highlight>
            </div>
          );
        }
        if (name === "h2") {
          const idIdex = ++id;
          h2h3List.push({
            tag: "h2",
            name: children[0].data,
            id: `t2-${idIdex}`,
          });
          return <h2 id={`t2-${idIdex}`}>{domToReact(children, options)}</h2>;
        }
        if (name === "h3") {
          const idIdex = ++id;
          h2h3List.push({
            tag: "h3",
            name: children.data || children[0].data || children[0].children[0].data || children[0].children[0].children[0].data,
            id: `t3-${idIdex}`,
          });
          return <h3 id={`t3-${idIdex}`}>{domToReact(children, options)}</h3>;
        }
        if (name === "h4") {
          const idIdex = ++id;
        //   console.log('fuck',children)
          h2h3List.push({
            tag: "h4",
            name: children.data || children[0].data || children[0].children[0].data || children[0].children[0].children[0].data,
            id: `t4-${idIdex}`,
          });
          return <h4 id={`t4-${idIdex}`}>{domToReact(children, options)}</h4>;
        }
        if (name === "img") {
          if (!attribs.src.startsWith("data")) {
            const styleobj = {};
            if (attribs.style) {
              attribs.style.split(";").map((item) => {
                styleobj[item.split(":")[0]] = item.split(":")[1];
              });
            }
            return (
              <img
                style={styleobj}
                alt=""
                src={
                  url_config.base_url + "/media" + attribs.src.split("media")[1]
                }
              />
            );
          }
          return;
        }
      },
    };
    renderedHtml = parse(html, options);
  }

  const sortArray = (arr) => {
    const result = [];
    let currentH2 = null;
    let currentH3 = null;

    for (const item of arr) {
      if (item.tag === "h2") {
        currentH2 = item;
        currentH2.children = [];
        result.push(currentH2);
      } else if (item.tag === "h3" && currentH2) {
        currentH3 = item;
        if (!currentH2.children) {
          currentH2.children = [];
        }
        currentH2.children.push(currentH3);
      } else if (item.tag === "h4" && currentH3) {
        const currentH4 = item;
        if (!currentH3.children) {
          currentH3.children = [];
        }
        currentH3.children.push(currentH4);
      }
    }

    return result;
  };

  const taglist = sortArray(h2h3List);

  const headImg =
    article.DetailImg &&
    url_config.base_url + "/media" + article.DetailImg.split("media")[1];

  return (
    <BlogDetailBox>
      <NavByTag>
        <NavTag>
          <div>
            {taglist.map((item) => (
              <ul key={item.id}>
                <li
                  onClick={() => {
                    setActiveTag(item.id);
                  }}
                  className={`c-${item.tag} ${
                    activeTag === item.id ? "active" : ""
                  }`}
                >
                  <a href={`#${item.id}`}>{item.name}</a>
                </li>
                {item.children &&
                  item.children.map((child) => (
                    <ul key={child.id}>
                      <li
                        onClick={() => {
                          setActiveTag(child.id);
                        }}
                        className={`c-${child.tag} ${
                          activeTag === child.id ? "active" : ""
                        }`}
                      >
                        <a href={`#${child.id}`}>{child.name}</a>
                      </li>
                      {child.children &&
                        child.children.map((grandchild) => (
                          <ul key={grandchild.id}>
                            <li
                              onClick={() => {
                                setActiveTag(grandchild.id);
                              }}
                              className={`c-${grandchild.tag} ${
                                activeTag === grandchild.id ? "active" : ""
                              }`}
                            >
                            <a href={`#${grandchild.id}`}>{grandchild.name}</a>
                            </li>
                          </ul>
                        ))}
                    </ul>
                  ))}
              </ul>
            ))}
          </div>
        </NavTag>
      </NavByTag>
      <BlogContent>
        <BlogTitle>{article.title}</BlogTitle>
        <BlogDescription>
          {article.tag &&
            article.tag.map((item, index) => (
              <Tag key={index}>{item.name}</Tag>
            ))}
          <BlogTime>{article.deliver_time}</BlogTime>
          <BlogReadTime>{article.estimated_time}min</BlogReadTime>
        </BlogDescription>
        <img src={headImg} alt="" className="briefImg" />
        {renderedHtml && renderedHtml}
      </BlogContent>
    </BlogDetailBox>
  );
};

export default BlogDetail;
