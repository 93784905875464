import styled from 'styled-components'

export const WriteContainer = styled.div`
  
`  
export const TitleInput = styled.input`
  padding: 1.5rem 2rem;
  width: 100%;
  font-size: 24px;
  border: none;
  outline: none;
`  
export const NextButton = styled.div`
   width: 6.3rem;
  font-size: 1.4rem;
  margin-top: 4rem;
`  
export const ModalContent = styled.div`
  h3{
      margin: 2rem 0;
      font-size: 99%;
  }
`  
