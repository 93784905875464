
import styled from 'styled-components'
// import bg1 from '../../assets/bg-1.jpg'
const editorWidth = '40rem'

export const EditorBox = styled.div`

  padding: 11rem 0;
  min-height: 40rem;
  border-radius: 8px;
   position: relative;
   overflow: hidden;
   .bg-video{
       position: absolute;
       top: 0;
       left: 0;
       width: 100%;
       height: 100%;
       object-fit: cover;
   }
  .upload-area{
   
   
  
   .editor-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5rem;
   }
   .questions{
       font-size: 1.3rem;
       padding: 1.3rem;
       color: rgba(0,0,0,0.7);
   }
  }
  .right{
      display: flex;
      align-items: center;
      padding: 1.3rem;
  }
  .tag{
      color: rgba(0,0,0,0.5);
      font-size: 1.3rem;
      margin-right: 2rem;
  }
 
`
export const UploadBtn = styled.div`
  width: 6.3rem;
  font-size: 1.4rem;

`
export const EditorContainer = styled.div`
  position: relative;
  margin: auto;
  width: ${editorWidth};
  overflow: hidden;
  background-color: #ffffff;
  border-radius: 6px;
  opacity: 0.9;
`