import styled from 'styled-components'

export const ArticleListBox = styled.div`
 border: 1px #bebebe solid;
 margin-top: 2rem;
 border-radius: 4px;
 padding: 1rem;
 padding-top: 2rem;
 max-height: 500px;
 overflow: auto;
 .BlogIcon{
    width: 4.6rem;
    height: 4.6rem;
    @media screen and (max-width: 1000px) {
      display: none;
    }
    
 }
 @media screen and (max-width: 1000px) {
        margin-top: 0;
        border: none;
        
        border-left: 1px #cfcdcd solid;
        border-radius: 0;
        flex: 1;
        padding-top: 0;
        padding-left: 2rem;
    }
@media screen and (max-width: 720px) {
    padding-top: 3rem;
    max-height: none;
    border: none;
}
@media screen and (max-width: 420px) {
    padding-top: 1rem;
}
`


export const ListItem = styled.div`
 font-size: 1.4rem;
 padding: 0.5rem 1.5rem;
 display: flex;
 @media screen and (max-width: 420px) {
    padding: 1rem 3.5rem;
}

`
export const ItemOuter = styled.div`
  /* padding: 0 5rem; */
`

export const ItemIndex = styled.span`
  margin-right: 1.5rem;
  width: 2rem;
`
export const ItemTitle = styled.div`
  cursor: pointer;
`
export const More = styled.div`
  cursor: pointer;
  font-size: 1.2rem;
  text-align: center;
  color: #7f7f7f;
  margin: 1.3rem 0 0.5rem 0;
  margin-top: 2rem;
  .MoreIcon{
    width: 1.1rem;
    transform: rotate(269deg);
    margin-right: 2px;
  }
`

export const Hr = styled.div`
height: 1px;
box-shadow: rgb(230 230 230) 0px -1px 0px inset;
display: none;
@media screen and (max-width: 720px) {
    display: block;
}
`;