import styled from 'styled-components'

const defaultHeight = '3.8rem'
const defaultHeightColoredBtn = '3.6rem'
const colorOutline = '#335dff'


export const BtnContainer = styled.div`
width: 100%;
height: 100%;
position:relative;
display: flex;
justify-content: center;
align-items: center;

.iconImg{
  width: 1.8rem;
  height: 1.8rem;
}
.btmHtml{
    display: flex;
    align-items: center;
    justify-content: ${({ textAlign }) => {
    switch (textAlign) {
      case 'left':
        return 'flex-start'
      case 'right':
        return 'flex-end'
      default:
        return 'center'
    }
  }};
    color: #335dff;
    font-size: 17px;
    font-weight: bold;
}
`

export const BaseButton = styled.button`
  background: lightblue;
  text-align: center;
  outline: none;
  border: none;
  cursor: pointer;
  width: 100%;
`

export const BtnAnimateOut = styled(BaseButton)`
  background: none;
  border: 1px #335dff solid;
  padding: 0rem 0.8rem;
  box-sizing: content-box;
  /* text-align: ${({ textAlign }) => textAlign ? textAlign : 'center'}; */
  position: relative;
  transition:all .3s ease;
  transition-delay: 0.05s;
  border-radius: 3px;
  z-index: 20;
  background: #fff;
  height: ${({ height }) => height ? height : defaultHeight};
  
  &:hover{
    transform: translate(0.3rem,-0.6rem);
    transition-delay: 0.05s;
  }

`
export const BtnAnimateIn = styled(BaseButton)`
  transform: translate(6px,-10px);
  background: #335dff;
  color: #fff;
  height: ${({ height }) => height ? height : defaultHeightColoredBtn};
  border-radius: 3px;
  font-size: 15px;
  z-index: 10;
  transition-delay: 0.05s;
  transition:all .2s ease;
  :hover{
    transform: translate(4px,-6px);
    transition-delay: 0.05s;
    box-shadow: 1px 1px 23px #aeb7dc;
  }
`

export const RegularBtn = styled(BaseButton)`
  background: #335dff;
  color: #fff;
  height: ${({ height }) => height ? height : defaultHeightColoredBtn};
  border-radius: 3px;
  /* font-size: 15px; */
  
`
export const Outline = styled.div`
    /* width: calc(100% - 3px);
    height: calc(100% - 3px); */
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    border-radius: 3px;
    z-index: 1;
    border: 1px  solid;
    border-color: ${({ color }) => color ? color : colorOutline}; 
    z-index: 1;
`