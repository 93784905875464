import styled from 'styled-components'

export const LoginContainer = styled.main`
.testBtn{
    width: 50px;
    height: 3.8rem;
}
   
`
export const Header = styled.header`
   
`
export const LoginBox = styled.div`
  max-width: 40rem;
  padding: 1.8rem;
  margin: auto;
  height: 100%;
  margin-top: 5rem;
   img{
       width: 10rem;
       /* height: 130px; */
   }
`
export const GoogleButton = styled.div`
/* width: 90%; */
flex: 1;
margin-right: 1.3rem;
.iconImg{
    margin-right: 1rem;
}
`
export const GithubButton = styled.div`
 width: 3.8rem;
 text-align: center;
`
export const LoginChoice = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
`
