import { SeriesBox,SeriesTitle,BlogTitle } from "./Series.style"
import axios from "axios"
import { useEffect,useState } from "react"
import { url_config } from "../../url_config"
import { useNavigate } from "react-router-dom"

interface Props{
    activeTag?: any
  }
  

const Series:React.FC<Props> = (props)=>{
    const [Series, setSeries ] = useState([])
    const [active, setActive] = useState<number | null>(-1)
    useEffect(()=>{
        axios.get(url_config.getSeries).then(res=>{
            if (res.status===200){
                setSeries(res.data)
            }
        })
    },[])
    useEffect(()=>{
        if (props.activeTag){
          setActive(props.activeTag)
        }
      },[props.activeTag])
    const navigate = useNavigate()
    const handleNavi=(id:number)=>{
        navigate('/archive',{
            state: {
                id: id,
                type: 'series'
            }
        })
    }
    return (
        <SeriesBox>
           <SeriesTitle>笔记专题</SeriesTitle>
           { Series.length && Series.map((item:any)=>{
               return <BlogTitle active={active === item.id} key={item.id} onClick={()=>handleNavi(item.id)}>{item.name} </BlogTitle>
           })}
        </SeriesBox>
    )
}

export default Series