import { Formik, Form } from 'formik';
import FormInput from '../../../components/Input/FormInput';
import * as Yup from 'yup';

// import useAxiosPrivate from '../../../hooks/usePrivateAxios';
// import { User } from '../../../context/user.context'; 
import type { UserInfo } from '../../../stateSlice/userDetail';
import AxiosInstance from '../../../hooks/axios';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { SET_CURRENT_USER } from '../../../stateSlice/userDetail';
import { useNavigate } from 'react-router-dom';
import { SignInBtn } from './form.style';
import Button from '../../../components/button/button.component';


export default function LoginForm() {
    const dispatch = useDispatch()
    const userDetail = useSelector(state => state)
    const navigate = useNavigate()
    return (
        <Formik
            initialValues={{
                email: '',
                password: ''
            }}
            validationSchema={Yup.object({
                email: Yup.string()
                    // .email('Invalid email address')
                    .required('Required'),
                password: Yup.string()
                    .max(6, 'input password of 6 digit')
                    .min(6, 'input password of 6 digit')
                    .required('Required')
            })}
            onSubmit={(values, { setSubmitting }) => {
                AxiosInstance.post<UserInfo>('/user/login', values, { withCredentials: true }).then(res => {
                    console.log('res', res.data)
                    dispatch(SET_CURRENT_USER(res.data))
                    console.log(userDetail);
                    window.localStorage.setItem('refresh', res.data.refresh)
                    window.localStorage.setItem('access', res.data.access)
                    navigate('/manage/profile')

                }, error => {
                    if (error.response.data.detail) {
                        alert(error.response.data.detail)
                    } else {
                        alert('请求失败')
                    }
                })
            }}
        >
            {({ isSubmitting }) => (
                <Form >
                    <FormInput
                        label="Email"
                        name="email"
                        type="text"
                        placeholder="jayden@example.coom"
                    />
                    <FormInput
                        label="Password"
                        name="password"
                        type="password"
                    />
                    <SignInBtn>
                        <Button buttonType='animateOut' buttonText='登录' height='3.8rem' textAlign='center'>
                        </Button>
                    </SignInBtn>
                </Form>
            )}

        </Formik>
    );
}
