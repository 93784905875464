import styled from "styled-components";
import { Link } from "react-router-dom";


export const Main = styled.div`
  /* max-width: 1444px; */
  margin: 0 auto;
  /* padding: 0 5rem; */
  padding-bottom: 4rem;
  min-height: 100vh;
  /* @media screen and (max-width: 720px) {
    padding: 0 2rem;
   } */
   @media screen and (max-width: 440px) {
    padding: 0 0;
   }
`;

export const Container = styled.div``;

export const Header = styled.header`
  /* display: flex;
  align-items: center;
  background: rgb(249, 249, 249);
  padding: 1rem 2rem;
  max-width: 1564px;
  margin:auto; */
  /* margin-bottom: 2rem; */
  background: rgb(249, 249, 249);
  box-shadow: 1px 1px 8px #d6d6d6;
`;
export const HeaderBox = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  max-width: 1494px;
  margin:auto;
  padding-right: 8rem;
  @media screen and (max-width: 790px) {
    padding-right: 4rem;
  }
  @media screen and (max-width: 540px) {
    padding-right: 2rem;
  }

`;
export const HeaderLeft = styled.nav`
  display: flex;
  align-items: center;
  img {
    width: 10rem;
    cursor: pointer;
    @media screen and (max-width: 540px) {
    width: 8rem;
  }
  }
  
`;
export const NavItem = styled(Link)`
  margin-left: 6rem;
  text-decoration: none;
  margin-bottom: -8px;
  color: black; ;
  @media screen and (max-width: 790px) {
    margin-left: 4rem;
  }
  @media screen and (max-width: 540px) {
    margin-left: 3rem;
  }
`;
export const NavItemTemp = styled.div`
  margin-left: 6rem;
  text-decoration: none;
  margin-bottom: -8px;
  cursor: pointer;
  color: black; ;
  @media screen and (max-width: 790px) {
    margin-left: 4rem;
  }
  @media screen and (max-width: 540px) {
    margin-left: 3rem;
  }
`;
export const HeaderRight = styled.nav`
  margin-left: auto;
  display: flex;
  align-items: center;
`;
export const LoginBtn = styled.div`
  width: 6rem;
  margin-left: 3rem;
  @media screen and (max-width: 790px) {
       font-size: 15px;
       width: 5.4rem;
  }
`;
