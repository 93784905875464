
import { EditorBox, UploadBtn, EditorContainer } from "./EditorBox.style"
import TextEditor from "../../components/TextEditor/TextEditor"
import Button from "../../components/button/button.component"
import BgVideo from '../../assets/white-sea.mp4'
import { useNavigate } from "react-router-dom";

const Editorbox = () => {
    const navigate = useNavigate()
    return (
        <EditorBox>
            <video className="bg-video" autoPlay loop playsInline muted >
                <source src={BgVideo} type="video/mp4" />
            </video>
            <EditorContainer>
                <TextEditor />
                <div className="upload-area">

                    <div className="editor-box">
                        {/* <div className="questions">
                            下一餐打算做什么呢？
                        </div> */}
                        <div className="right">
                            {/* <div className="tag">
                                常规+
                            </div> */}
                            <UploadBtn>
                                <Button buttonType='animateOut' buttonText='随手记' onClick={()=>{ navigate('/login')}} height='3rem' textAlign='center'>
                                </Button>
                            </UploadBtn>
                        </div>
                    </div>
                </div>
            </EditorContainer>
        </EditorBox>
    )
}

export default Editorbox