import styled from 'styled-components'



export const Content = styled.div`
    display: flex;
    justify-content: space-between;
    margin: auto;
    padding: 0 5rem;
    max-width: 1444px;
    margin-top: 2rem;
    
    @media screen and (max-width: 1000px) {
        flex-direction: column;
        padding: 0 0rem;
    }
`
export const ContentLeft = styled.div`
    flex: 1;
    @media screen and (max-width: 1000px) {
        order: 2;
        padding: 0 2.5rem;
    }
    @media screen and (max-width: 720px) {
        padding: 0 1rem;
    }
`
export const ContentRight = styled.div`
    flex-basis: 300px;
    margin-left: 2rem;
    flex-shrink: 0;
    justify-content: space-between;
    
    @media screen and (max-width: 1000px) {
        display: flex;
        order: 1;
        margin-left: 0;
        margin-bottom: 4rem;
    }
    @media screen and (max-width: 720px) {
        display: block;
    }
`

