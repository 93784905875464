import { BookmarkBox, NavPannel, Content, Item } from "./Bookmark.style";
import NavBar from "../../components/NavBar/NavBar";
import { BookMarkNav } from "../../components/NavBar/navbar.style";
import axios from 'axios'
import { url_config } from "../../url_config";
import { useEffect, useState } from "react";





const Bookmark = () => {
  const [navs, setNavs] = useState([])
  const [bookmarks, setBookMarks ]= useState([])
  const changeEvent = (index:number)=>{
    return new Promise((resolve,reject)=>{
      axios.post(url_config.filterByTag,{tagId: index}).then(res=>{
        if (res.status ===200){
          setBookMarks(res.data)
          resolve(res.data)
        }
      })
    })
  }
  useEffect(()=>{
    axios.post(url_config.filterByTag,{tagId: 1}).then(res=>{
      if (res.status ===200){
        setBookMarks(res.data)
      }
    })
  },[])
  useEffect(()=>{
    axios.get(url_config.bookmarkTag).then(res=>{
      console.log('res',res)
      if (res.status ===200){
        setNavs(res.data)
      }
    })
  },[])
  
  return (
    <BookmarkBox>
      <NavPannel>
        <svg
          className="Bookmark"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="3484"
        >
          <path
            d="M204.8 392.533333c-20.48 0-34.133333-13.653333-34.133333-34.133333s13.653333-34.133333 34.133333-34.133333h102.4c20.48 0 34.133333 13.653333 34.133333 34.133333s-13.653333 34.133333-34.133333 34.133333H204.8z"
            fill="#1296db"
            p-id="3485"
          ></path>
          <path
            d="M785.066667 699.733333c0-20.48 13.653333-34.133333 34.133333-34.133333s34.133333 13.653333 34.133333 34.133333v68.266667c0 58.026667-44.373333 102.4-102.4 102.4H341.333333c-58.026667 0-102.4-44.373333-102.4-102.4v-546.133333c0-58.026667 44.373333-102.4 102.4-102.4h409.6c58.026667 0 102.4 44.373333 102.4 102.4v341.333333c0 20.48-13.653333 34.133333-34.133333 34.133333s-34.133333-13.653333-34.133333-34.133333v-341.333333c0-20.48-13.653333-34.133333-34.133334-34.133334H341.333333c-20.48 0-34.133333 13.653333-34.133333 34.133334v546.133333c0 20.48 13.653333 34.133333 34.133333 34.133333h409.6c20.48 0 34.133333-13.653333 34.133334-34.133333v-68.266667z"
            fill="#1296db"
            p-id="3486"
          ></path>
          <path
            d="M204.8 665.6c-20.48 0-34.133333-13.653333-34.133333-34.133333s13.653333-34.133333 34.133333-34.133334h102.4c20.48 0 34.133333 13.653333 34.133333 34.133334s-13.653333 34.133333-34.133333 34.133333H204.8z"
            fill="#1296db"
            p-id="3487"
          ></path>
          <path
            d="M525.653333 508.586667c-10.24-17.066667-3.413333-37.546667 13.653334-44.373334 17.066667-10.24 37.546667-3.413333 44.373333 13.653334 20.48 40.96 37.546667 47.786667 64.853333 47.786666 10.24 0 13.653333-6.826667 13.653334-30.72 0-64.853333-54.613333-119.466667-119.466667-119.466666s-119.466667 54.613333-119.466667 119.466666S477.866667 614.4 542.72 614.4c20.48 0 34.133333 13.653333 34.133333 34.133333s-13.653333 34.133333-34.133333 34.133334c-102.4 0-187.733333-85.333333-187.733333-187.733334S440.32 307.2 542.72 307.2s187.733333 85.333333 187.733333 187.733333c0 58.026667-27.306667 95.573333-75.093333 98.986667-51.2 3.413333-95.573333-17.066667-129.706667-85.333333z"
            fill="#1296db"
            p-id="3488"
          ></path>
          <path
            d="M546.133333 563.2c37.546667 0 68.266667-30.72 68.266667-68.266667s-30.72-68.266667-68.266667-68.266666-68.266667 30.72-68.266666 68.266666 30.72 68.266667 68.266666 68.266667z"
            fill="#1296db"
            p-id="3489"
          ></path>
        </svg>
        <BookMarkNav> 
            <NavBar navItems={navs} changeEvent={changeEvent} ></NavBar> 
        </BookMarkNav>
       
      </NavPannel>
      <Content>
        {bookmarks.length && bookmarks.map((i:any, index) => {
          return (
            <Item key={index} href={i.website} target='_blank'>
              <div className="title">{ i.name }</div>
              <div className="description">{ i.description}</div>
            </Item>
          );
        })}
      </Content>
    </BookmarkBox>
  );
};

export default Bookmark;
