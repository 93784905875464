import styled from 'styled-components'

export const BlogDetailBox = styled.div`
   
   padding: 0 5rem;
   max-width: 1400px;
   padding-top: 2rem;
   display: flex;
   /* border: 1px red solid; */
   min-height: 100vh;
   justify-content: center;
   margin: auto;
   width: 100%;
   font-family: "lantinghei", monospace;
   /* @media screen and (max-width: 1500px) {
     width: 100%;
  } */
  @media screen and (max-width: 1000px) {
    padding: 2rem;
    margin-top: 2rem;
  }
  @media screen and (max-width: 700px) {
    padding: 0;
    margin: 0;
  }
   
   .briefImg{
       width: 100%;
       display: block;
       margin: auto;
       height: 50rem;
       object-fit: cover;
       @media screen and (max-width: 1250px) {
        height: 40rem;
       }
       @media screen and (max-width: 630px) {
        height: 30rem;
       }
       @media screen and (max-width: 520px) {
        height: 20rem;
       }

   }
   .copy-icon{
    width: 20px;
    height: 20px;
    transform: rotateY(180deg);
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    display:none;
   }
    
`
export const NavTag = styled.div`
top: 13vh; 
position: fixed;
width: 23rem;
height: calc(100vh - 13vh);
padding-bottom: 10rem;
overflow-y: auto;
&::-webkit-scrollbar {
   display: none; /* Chrome Safari */
}
@media screen and (max-width: 1600px) {
    margin-left: 0;
    width: 20rem;
  }
`
export const NavByTag = styled.div`
position: relative;
width: 25rem;
flex-shrink: 0;
border-right: rgb(230 230 230) 1px solid;
box-sizing: border-box;
min-height: 20vh;
margin-left: -4rem;

@media screen and (max-width: 1600px) {
    margin-left: 0;
    width: 20rem;
  }
@media screen and (max-width: 800px) {
    display: none;
  }
  ul{
        list-style: none;
        font-size: 1.4rem;
        cursor: pointer;
        margin-bottom: 0!important;
        
        ul{ 
            margin-left: 1rem;
        }

        li{
            /* padding: 1rem 2rem; */
            &:hover{
                color: #89999b;
            }
        }
        .active{
            border-left: 4px solid #086DD7;
            background: #F9F9FA;
            font-weight: bold;
            color: #086DD7;
        }
        a{
            color:inherit;
            padding: 1rem 2rem;
            text-decoration:none;
            width: 100%;
            display: inline-block;

        }
    }
`
export const BlogContent = styled.div`
 margin-left: 4rem;
 min-height: 100vh;
 overflow: auto;
 margin-right: 2rem;
 padding: 3rem;
 color: #323232;
 /* color: #1b325a; */
 
 th{
  /* background: #eeeeee; */
  padding: 10px;
  border: 1px #008080 solid;
 }
 td{
  padding: 10px 10px;
  border: 1px #008080 solid;


 }
 flex: 1;
 /* background-color: aliceblue; */
 @media screen and (max-width: 1400px) {
    margin-left: 2rem;
  }
  @media screen and (max-width: 1000px) {
    margin-left: 2rem;
  }
  @media screen and (max-width: 700px) {
    padding: 2rem;
    margin: 0;
    overflow: hidden;
  }
 p{
     /* font-size: 1.5rem; */
     line-height: 2.6rem;
     overflow: hidden;
     margin-bottom: 0;
     margin-top: 13px;
 }
 blockquote{
  margin: 13px;
  margin-top: 13px;
  background: #e8e8f2;
  /* color: #999999; */
  line-height: 2.6rem;
  border-left: 8px solid #49499c;
  border-radius: 10px;
  padding: 10px;
  padding-left: 14px;
 }
 h1, h2, h3, h4, h5, h6{
    margin-bottom: 0;
    font-weight: bold;
    line-height: 3rem;
 }
 h2,h3,h4{
     margin: 2rem 0;
     margin-left:-0.5rem;
 }
 strong{
  color:#323290;
 }
 h3{
    font-size: 1.9rem;
    @media screen and (max-width: 700px) {
    font-size: 1.5rem;
   }
 }
 h2{
     margin: 3rem 0 2rem 0;
 @media screen and (max-width: 700px) {
    font-size: 1.8rem;
   }
 }
 .prism-code{
     padding: 1rem 0.8rem;
     padding-top: 2rem;
     position: relative;
 }
 .prism-code:hover{
  .copy-icon{
    display: block;
  }
 }
 .token-line{
     margin: 0.8rem 0;
 }
 ul{
    margin: 1rem 2rem;
    li{
         /* margin: 1rem 0; */
         line-height: 2.8rem;
     }
 }
 ol{
     
     li{
         margin: 1rem 0;
     }
 }
 img{
     max-width: 100%;
 }

 
`
export const BlogTitle = styled.h1`
 margin-top: 1.5rem;
 font-weight: bold;
 line-height: 5rem!important;
 font-size: 3.8rem;
 @media screen and (max-width: 700px) {
    font-size: 2.4rem;
  }
`
export const BlogTime = styled.span`
 margin-right: 2rem;
 margin-left: 1rem;
`
export const BlogReadTime = styled.span`

`
export const BlogDescription = styled.div`
margin: 2rem 0;
color: #8a919f;
font-size: 1.4rem;
`

export const Tag = styled.span`
 background-color: #4c9fe8;
 margin: 0 1rem;
 padding: 1px 8px;
 border-radius: 3px;
 color: #ffffff;
`