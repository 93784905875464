import { TagsBox, TagTitle, Tag, TagGroup } from "./Tags.style";
import axios from "axios"
import { useEffect, useState } from "react"
import { url_config } from "../../url_config"
import { useNavigate } from "react-router-dom"

interface Props{
  activeTag?: any
}

const Tags:React.FC<Props> = (props) => {
  const [tags, setTags] = useState([])
  const [active, setActive] = useState<number | null>(-1)
  useEffect(() => {
    axios.get(url_config.getTagsWithCount).then(res => {
      if (res.status === 200) {
        setTags(res.data.tags)
      }
    })
  }, [])
  useEffect(()=>{
    if (props.activeTag){
      setActive(props.activeTag)
    }
  },[props.activeTag])
  const navigate = useNavigate()
  const handleNavi = (id: number) => {
    setActive(id)
    navigate('/archive', {
      state: {
        id: id,
        type: 'tag'
      }
    })
  }
  return (
    <TagsBox>
      <TagTitle>标签</TagTitle>
      <TagGroup>
        {tags.length && tags.map((i: any) => {
          return <Tag onClick={() => handleNavi(i.id)} key={i.id} active={active === i.id}>{i.name} <span>  ({i.num_articles})</span></Tag>
        })}
      </TagGroup>
    </TagsBox>
  );
};

export default Tags;
