import NavBar from "../../components/NavBar/NavBar";
import { ArticleListNav } from "../../components/NavBar/navbar.style";

import {
  ArticleListBox,
  ListItem,
  ItemIndex,
  ItemTitle,
  More,
  ItemOuter,
  Hr
} from "./ArticleList.style";
import useGetArticles from "../../hooks/useGetArticles";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';


interface tag {
  id: number,
  name: string
}
export interface Article {
  briefImg: string,
  content: string,
  deliver_time: string,
  estimated_time: number,
  id: number,
  last_visit: string,
  off_used: number,
  series: Object,
  tag: tag[],
  title: string,
  visits: number
}

const navItems = ['常用', '最近']

const testArticles = [
  { title: '会尽快了哈荆防颗粒等哈干哈货到付款杰拉华刚' },
  { title: '会尽快了哈荆防颗粒等哈干哈' },
  { title: '会尽快了哈荆防颗粒等哈干哈' },
  { title: '会尽快了哈荆防颗粒等哈干哈' },
  { title: '会尽快了哈荆防颗粒等哈干哈' },
  { title: '会尽快了哈荆防颗粒等哈干哈' },
  { title: '会尽快了哈荆防颗粒等哈干哈' },
  { title: '会尽快了哈荆防颗粒等哈干哈' },
  { title: '会尽快了哈荆防颗粒等哈干哈' },
  { title: '会尽快了哈荆防颗粒等哈干哈' },


]
const ArticleList = () => {
  const [condition, setCondition] = useState('often_use')
  const navigate = useNavigate()
  const changeEvent = (index: number) => {
    return new Promise((resolve, reject) => {
      if (index === 0) {
        setCondition('often_use')
      } else {
        setCondition('deliver_time')
      }
      resolve(index)
    })
  }
  const articles: Article[] = useGetArticles<Article[]>({ sortBy: condition }).slice(0,10)
  
  const naviBlog=()=>{
    navigate('/blog')
  }

  return (
    <ArticleListBox>
      <Hr/>
      <div style={{ textAlign: "center" }}>
        <svg
          className="BlogIcon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="4041"
        >
          <path
            d="M921.6 0H102.4C46.08 0 0 46.08 0 102.4v819.2c0 56.32 46.08 102.4 102.4 102.4h819.2c56.32 0 102.4-46.08 102.4-102.4V102.4c0-56.32-46.08-102.4-102.4-102.4zM716.8 358.4V409.6c0 30.72 20.48 51.2 51.2 51.2S819.2 481.28 819.2 512v153.6c0 87.04-66.56 153.6-153.6 153.6h-307.2c-87.04 0-153.6-66.56-153.6-153.6V307.2c0-87.04 66.56-153.6 153.6-153.6h204.8c87.04 0 153.6 66.56 153.6 153.6v51.2zM409.6 409.6h133.12c30.72 0 51.2-20.48 51.2-51.2S573.44 307.2 542.72 307.2H409.6c-30.72 0-51.2 20.48-51.2 51.2S378.88 409.6 409.6 409.6z m204.8 153.6H409.6c-30.72 0-51.2 20.48-51.2 51.2s20.48 51.2 51.2 51.2h204.8c30.72 0 51.2-20.48 51.2-51.2s-20.48-51.2-51.2-51.2z"
            p-id="4042"
            fill="#00cec9"
          ></path>
        </svg>
      </div>
      <ArticleListNav>
        <NavBar navItems={navItems} changeEvent={changeEvent} ></NavBar>
      </ArticleListNav>
      <ItemOuter>
        {articles.map((item, index) => {
          return (
            <ListItem key={index}>
              <ItemIndex>{index + 1}.</ItemIndex>
              <ItemTitle>{item?.title}</ItemTitle>
            </ListItem>
          );
        })}
      </ItemOuter>


      <More onClick={naviBlog}>
        <svg
          className="MoreIcon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="6478"
        >
          <path
            d="M494.4896 955.6992c4.7104 4.5056 11.0592 7.0656 17.6128 7.0656 6.5536 0 12.9024-2.56 17.6128-7.0656m0.1024 0l474.112-454.0416c20.8896-19.968 19.5584-53.1456-1.2288-73.0112s-54.3744-19.968-75.1616-0.1024l-415.3344 397.312-415.232-397.312c-20.8896-19.968-54.4768-19.7632-75.1616 0.1024C0.9216 448.4096-0.4096 481.6896 20.48 501.5552l474.112 454.0416h35.2256z m0 0"
            p-id="6479"
            fill="#7f7f7f"
          ></path>
          <path
            d="M494.1824 603.3408c4.7104 4.5056 11.0592 6.9632 17.6128 6.9632 6.5536 0 12.9024-2.56 17.6128-6.9632m0.1024 0l474.112-454.0416c20.8896-19.968 19.5584-53.1456-1.2288-73.0112s-54.3744-19.968-75.1616-0.1024l-415.3344 397.312-415.3344-397.312c-20.8896-19.968-54.4768-19.7632-75.1616 0.1024C0.6144 96.1536-0.7168 129.4336 20.1728 149.2992l474.112 454.0416h35.2256z m0 0"
            p-id="6480"
            fill="#7f7f7f"
          ></path>
        </svg>
        更多
      </More>
    </ArticleListBox>
  );
};

export default ArticleList;
