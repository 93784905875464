import { useNavigate } from "react-router-dom";


const useStorageArticle = ()=>{
    const navigate = useNavigate()
    const getArticle=()=>{
        return JSON.parse(localStorage.getItem('article'))
    }
    const StoreArticle=(article)=>{
        localStorage.setItem('article',JSON.stringify(article))
        navigate(`/blog-detail?id=${article.id}`)
    }
   return { getArticle, StoreArticle}
}

export default useStorageArticle