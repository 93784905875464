import styled from 'styled-components'


export const BlogBriefContent = styled.div`
  margin-bottom: 3rem;
  padding-bottom: 2rem;
  box-shadow: rgb(230 230 230) 0px -1px 0px inset;
.flex-2{
  display: flex ;
  justify-content: space-between;
  .briefImg{
    width: 11rem;
    height: 8rem;
    object-fit: cover;
    @media screen and (max-width: 700px) {
      width: 8rem;
      height: 5rem;
   }
  }
  @media screen and (max-width: 700px) {
      padding: 3rem 0;
      padding-bottom: 0.6rem;
      margin-bottom: 0;
   }
}
  
`
export const Briefs = styled.div`
  margin-right: 6.5rem;
  cursor: pointer;
  @media screen and (max-width: 1060px) {
        margin-right: 3rem;
  }
`

export const BriefTitle = styled.div`
 font-size: 18px;
 font-weight: bold;
 @media screen and (max-width: 700px) {
  font-size: 17px;
}
@media screen and (max-width: 500px) {
  font-size: 15px;
}
`
export const BriefDes = styled.div`
  color: rgba(41, 41, 41, 1);
  font-size: 14px;
  margin-top: 1rem;
  @media screen and (max-width: 700px) {
      display: none;
  }
`
export const Des = styled.div`
   display: flex;
   margin-top: 1rem;
   color: rgba(41, 41, 41, 1);
   align-items: center;;
   font-size: 14px;
   span{
    color: rgba(117, 117, 117, 1);
    font-size: 12px;
    position: relative;
    bottom: 2px;
   }
   
`
export const Tag = styled.div`
   background-color: rgba(242, 242, 242, 1);
   padding: 4px 10px;
   border-radius: 50px;
   font-size: 13px;
   
`
export const Time = styled.div`
   margin: 0 2rem;
   color: rgba(117, 117, 117, 1);
   @media screen and (max-width: 500px) {
     margin: 0 1rem;
   }
`
export const TimeConsume = styled.div`
  color: rgba(117, 117, 117, 1);
  
  @media screen and (max-width: 500px) {
    margin-left: 1rem;
   }
`