import { NotesContent, NotesContainer } from "./index.style"
import NavBar from "../../components/NavBar/NavBar";
import { NoteNav } from "../../components/NavBar/navbar.style";
import Note from "./Note";
import usePageBottom from "../../hooks/usePageBottom";
import {  useState } from "react";



const navItems = [
    '全部',
    '编程',
    '生活',
    '其他'
]
const Notes = [
    {
        time: '23 June, 2022',
        content: '222等哈首付款黑咖喱火锅店饭卡合计过咔 哒很尬开始的饭卡联合国阿斯卡规范化 沙发考拉海购看哈'
    },
    {
        time: '23 June, 2022',
        content: '等哈首付款黑咖喱火锅店饭卡合计过咔'
    },
    {
        time: '23 June, 2022',
        content: '答复哈会计核算管理会计哈达卡拉季司法过贺卡给看回放工卡花港饭店看见啊很尬等哈首付款黑咖喱火锅店饭卡合计过咔 哒很尬开始的饭卡联合国阿斯卡规范化 沙发考拉海购看哈'
    },
    {
        time: '23 June, 2022',
        content: '等哈首付款黑咖喱火锅店饭卡合计过咔 哒很尬开始的饭卡联合国阿斯卡规范化 沙发考拉海购看哈'
    },
    {
        time: '23 June, 2022',
        content: '等哈首付款黑咖喱火锅店饭卡合计过咔'
    },
    {
        time: '23 June, 2022',
        content: '答复哈会计核算管理会计哈达卡拉季司法过贺卡给看回放工卡花港饭店看见啊很尬等哈首付款黑咖喱火锅店饭卡合计过咔 哒很尬开始的饭卡联合国阿斯卡规范化 沙发考拉海购看哈'
    },
    {
        time: '23 June, 2022',
        content: '等哈首付款黑咖喱火锅店饭卡合计过咔 哒很尬开始的饭卡联合国阿斯卡规范化 沙发考拉海购看哈'
    },
    {
        time: '23 June, 2022',
        content: '等哈首付款黑咖喱火锅店饭卡合计过咔'
    },
    {
        time: '23 June, 2022',
        content: '333答复哈会计核算管理会计哈达卡拉季司法过贺卡给看回放工卡花港饭店看见啊很尬等哈首付款黑咖喱火锅店饭卡合计过咔 哒很尬开始的饭卡联合国阿斯卡规范化 沙发考拉海购看哈'
    },
    {
        time: '23 June, 2022',
        content: '等哈首付款黑咖喱火锅店饭卡合计过咔'
    },
    {
        time: '23 June, 2022',
        content: '答复哈会计核算管理会计哈达卡拉季司法过贺卡给看回放工卡花港饭店看见啊很尬等哈首付款黑咖喱火锅店饭卡合计过咔 哒很尬开始的饭卡联合国阿斯卡规范化 沙发考拉海购看哈'
    },
    {
        time: '23 June, 2022',
        content: '等哈首付款黑咖喱火锅店饭卡合计过咔 哒很尬开始的饭卡联合国阿斯卡规范化 沙发考拉海购看哈'
    },
    {
        time: '23 June, 2022',
        content: '等哈首付款黑咖喱火锅店饭卡合计过咔'
    },
    {
        time: '23 June, 2022',
        content: '答复哈会计核算管理会计哈达卡拉季司法过贺卡给看回放工卡花港饭店看见啊很尬等哈首付款黑咖喱火锅店饭卡合计过咔 哒很尬开始的饭卡联合国阿斯卡规范化 沙发考拉海购看哈'
    },
    {
        time: '23 June, 2022',
        content: '等哈首付款黑咖喱火锅店饭卡合计过咔 哒很尬开始的饭卡联合国阿斯卡规范化 沙发考拉海购看哈'
    },
    {
        time: '23 June, 2022',
        content: '等哈首付款黑咖喱火锅店饭卡合计过咔'
    },
    {
        time: '23 June, 2022',
        content: '333答复哈会计核算管理会计哈达卡拉季司法过贺卡给看回放工卡花港饭店看见啊很尬等哈首付款黑咖喱火锅店饭卡合计过咔 哒很尬开始的饭卡联合国阿斯卡规范化 沙发考拉海购看哈'
    },
    {
        time: '23 June, 2022',
        content: '等哈首付款黑咖喱火锅店饭卡合计过咔'
    },
    {
        time: '23 June, 2022',
        content: '答复哈会计核算管理会计哈达卡拉季司法过贺卡给看回放工卡花港饭店看见啊很尬等哈首付款黑咖喱火锅店饭卡合计过咔 哒很尬开始的饭卡联合国阿斯卡规范化 沙发考拉海购看哈'
    },
    {
        time: '23 June, 2022',
        content: '等哈首付款黑咖喱火锅店饭卡合计过咔 哒很尬开始的饭卡联合国阿斯卡规范化 沙发考拉海购看哈'
    },
    {
        time: '23 June, 2022',
        content: '等哈首付款黑咖喱火锅店饭卡合计过咔'
    },
    {
        time: '23 June, 2022',
        content: '答复哈会计核算管理会计哈达卡拉季司法过贺卡给看回放工卡花港饭店看见啊很尬等哈首付款黑咖喱火锅店饭卡合计过咔 哒很尬开始的饭卡联合国阿斯卡规范化 沙发考拉海购看哈'
    },
    {
        time: '23 June, 2022',
        content: '等哈首付款黑咖喱火锅店饭卡合计过咔 哒很尬开始的饭卡联合国阿斯卡规范化 沙发考拉海购看哈'
    },
    {
        time: '23 June, 2022',
        content: '等哈首付款黑咖喱火锅店饭卡合计过咔'
    },
    {
        time: '23 June, 2022',
        content: '333答复哈会计核算管理会计哈达卡拉季司法过贺卡给看回放工卡花港饭店看见啊很尬等哈首付款黑咖喱火锅店饭卡合计过咔 哒很尬开始的饭卡联合国阿斯卡规范化 沙发考拉海购看哈'
    },

]

const NoteEverything = () => {
    const [notes, setNotes] = useState(Notes)
    const [isLocked, setIsLocked] = useState(false)
    const changeEvent = (index: number) => {
        console.log('index', index)
    }
    const isBottom = usePageBottom()
    if (isBottom && !isLocked){
        setIsLocked(true)
        setNotes((prev=>{
            return [...prev, ...Notes]
        }))
    }
    if (!isBottom && isLocked){
        setIsLocked(false)
    }
    return (
        <NotesContent>
            <NoteNav>
                <NavBar navItems={navItems} changeEvent={changeEvent} ></NavBar>
            </NoteNav>
            {/* <Hr/> */}
            <NotesContainer>
                {
                    notes.map((item, index) => <Note className="note-each" key={index} time={item.time} content={item.content} />)
                }
            </NotesContainer>
        </NotesContent>

    );
};

export default NoteEverything;
