import styled from "styled-components";

export const InputBox = styled.div`
 margin-top: 2rem;
`

export const Field = styled.label`
 display: block;
 font-weight: bold;
 margin-top: 1rem;
 margin-bottom: 0.6rem;
`
export const Input = styled.input`
border: 1px #e7dada solid;
border-radius: 4px;
padding: 10px;
width: 100%;
outline: none;
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(158,169,196);
  opacity: 1; /* Firefox */
}
`
export const Error = styled.div`
color: #ff0000;
font-size: 14px;
margin-top: 0.5rem;
`