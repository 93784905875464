import {
  RecentBox,
  RecentTitle,
  RecentItem,
  RecentItemTitle,
  VisitTime,
  ReadTime,
  Briefs,
} from "./RecentVisit.style";
import { Article } from "../Home/\bArticleList";
import useStorageArticle from "../../hooks/useStorageArticle";

type Props = {
  article: Article[]
}

const RecentVisit: React.FC<Props> = ({ article }) => {
  const {StoreArticle} = useStorageArticle()
  return (
    <RecentBox>
      <RecentTitle>最近阅读</RecentTitle>
      {article.length && article.map((item: Article) => (
        <RecentItem key={item.id} onClick={()=>{ StoreArticle(item)}}>
          <RecentItemTitle>
            {item.title}
          </RecentItemTitle>
          <Briefs>
            <VisitTime>{item.last_visit}</VisitTime>
            <span className="dot">.</span>
            <ReadTime>{item.estimated_time}分钟</ReadTime>
          </Briefs>
        </RecentItem>
      ))}
    </RecentBox>
  );
};

export default RecentVisit;
