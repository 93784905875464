
import { Content, ContentLeft, ContentRight } from "./Home.style"

import Editorbox from "./EditorBox"
import FocusList from "./FocusList"
import ArticleList from "./\bArticleList"
import Bookmark from "./Bookmark"
import useWindowSize from "../../hooks/useWindowSize"

const Home = () => {
  const {width} = useWindowSize()
  return (
      <Content>
        <ContentLeft>
          {width && width > 600 && <Editorbox />}
          {width && width > 600 && <Bookmark />}
        </ContentLeft>
        <ContentRight>
           <FocusList />
           <ArticleList />
        </ContentRight>
      </Content>
  )
}

export default Home