import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { base_url, url_config } from '../url_config';

export interface UserInfo{
  user_email: string,
  user_id: number | string,
  user_name: string,
  refresh: string,
  access: string,
  roles: number[]
}

interface DetailState{
    value: UserInfo
}

export const getNewToken = createAsyncThunk('detail/newToken', async (data:object)=>{
  const response = await axios.post(base_url+ url_config.getAccessWithRefresh, data);
  console.log('获取新token',response.data)
  return response.data
})

const initialState ={
  value: {}
} as DetailState

const detailSlice = createSlice({
  name: 'detail',
  initialState: initialState,
  reducers: {
    SET_CURRENT_USER: (state,action:PayloadAction<UserInfo>)=>{
        state.value = action.payload
    }
  },
  extraReducers: builder=>{
    builder
    .addCase(getNewToken.pending,(state,action)=>{
      console.log("hello, i'm pendin")
    })
    .addCase(getNewToken.fulfilled,(state,action)=>{
      console.log('收到啥',action.payload)
      // state.value.access = action.payload
    })
  }
})


export const { SET_CURRENT_USER } = detailSlice.actions
export default detailSlice.reducer