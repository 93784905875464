import { WriteContainer, TitleInput, NextButton, ModalContent, } from "./WriteArticle.style"
import '@wangeditor/editor/dist/css/style.css'

import { Editor, Toolbar } from '@wangeditor/editor-for-react'
import { useState, useEffect } from 'react'
import Button from "../../../components/button/button.component"
import Modal from "../../../components/Modal"
import UploadComponent from "../../../components/Upload/Upload";

import { IDomEditor, IEditorConfig, IToolbarConfig } from '@wangeditor/editor'
import SeriesDropDown from './SeriesDropDown'
import TagGroups from "./TagGroup"
import IsNewArticle from "./IsNewArticle"
import axios from "axios"
import { url_config } from "../../../url_config"
import { message } from "antd"
import {  useNavigate,useSearchParams } from 'react-router-dom';


interface ID {
    id: number
}

interface Article {
    title: string,
    content: string,
    series?: ID,
    tag: ID[],
    deliver_time: string,
    briefImg: string,
    DetailImg: string
    
}
type InsertFnType = (url: string, alt: string, href: string) => void

interface ImgResponse{
    image: string,
    small_image: string
}

const WriteArticle = () => {
    const [editor, setEditor] = useState<IDomEditor | null>(null)
    const [html, setHtml] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [series, setSeries] = useState(0) 
    const [title, setTitle] = useState('')
    const [date, setDate] = useState('')
    const [isNew, setIsNew] = useState(true)
    const [img, setImg] = useState<ImgResponse[] | []>([])
    const [selectedTag, setSelectedTag] = useState<number[]>([])
    const navigate = useNavigate()
    const [params] = useSearchParams()
    const id = params.get('id')
    useEffect(() => {
        if (id){
            axios.get(url_config.articles + id + '/').then(res => {
                if (res.status === 200) {
                    const data = res.data
                    setHtml(data.content)
                    setTitle(data.title)
                    if (data.series){
                        setSeries(data.series?.id)
                    }
                    const temp:number[] =[]
                    data.tag.map((i:any)=>temp.push(i.id))
                    setSelectedTag(temp)
                    setImg([{small_image:data.briefImg,image:data.DetailImg}])
                    
                }
            })
        }
    }, [id])


    const toolbarConfig: Partial<IToolbarConfig> = {}  // TS 语法

    const editorConfig: any = {    // TS 语法
        placeholder: 'heheha请输入内容...',
        MENU_CONF: {}
    }
//     editorConfig.MENU_CONF['uploadImage'] = {
        
//    }
    editorConfig.MENU_CONF['uploadImage'] = {
        fieldName: 'image',
        server: url_config.imgUploadTo,
        // 上传之前触发
        onBeforeUpload(file: File) { // TS 语法
        // onBeforeUpload(file) {    // JS 语法
            // file 选中的文件，格式如 { key: file }
            console.log('hi',file)
            return file
    
            // 可以 return
            // 1. return file 或者 new 一个 file ，接下来将上传
            // 2. return false ，不上传这个 file
        },
    
        // 上传进度的回调函数
        onProgress(progress: number) {  // TS 语法
        // onProgress(progress) {       // JS 语法
            // progress 是 0-100 的数字
            console.log('progress', progress)
        },
    
        // 单个文件上传成功之后
        onSuccess(file: File, res: any) {  // TS 语法
        // onSuccess(file, res) {          // JS 语法
            console.log(`${file.name} 上传成功`, res)
        },
    
        // 单个文件上传失败
        onFailed(file: File, res: any) {   // TS 语法
        // onFailed(file, res) {           // JS 语法
            console.log(`${file.name} 上传失败`, res)
        },
    
        // 上传错误，或者触发 timeout 超时
        onError(file: File, err: any, res: any) {  // TS 语法
        // onError(file, err, res) {               // JS 语法
            console.log(`${file.name} 上传出错`, err, res)
        },
        customInsert(res: any, insertFn: InsertFnType) {  // TS 语法
            // customInsert(res, insertFn) {                  // JS 语法
                // res 即服务端的返回结果
                console.log('res图片',res)
                insertFn(res.image,'',res.image)
                // 从 res 中找到 url alt href ，然后插入图片
            // insertFn(url, alt, href)
        },
    }
    const onFileChange = (urls:any) => {
        setImg(urls)
    }
    useEffect(() => {
        return () => {
            if (editor == null) return
            editor.destroy()
            setEditor(null)
        }
    }, [editor])
    const handleSelectTag = (ids: number[]) => {
        setSelectedTag(ids)
    }
    const getCurrentTime = () => {
        const now = new Date()
        return `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`
    }
    const checkValid = () => {
        return img.length > 0 && html && title && selectedTag.length > 0
    }
    const collectData = ()=>{
        const data: Partial<Article> = {
            content: html,
            title: title
        }
        if (series) {
            data.series = {
                id: series
            }
        }
        data.briefImg = img[0].small_image
        data.DetailImg = img[0].image
        data.tag = selectedTag.map(i => ({ id: i }))
        return data
    }
    const handleSubmit = () => {
        if (checkValid()) {
            const data = collectData()
            date ? data.deliver_time = date : data.deliver_time = getCurrentTime()
            axios.post(url_config.articles, data).then(res => {
                if (res.status === 201){
                    message.success('保存成功')
                    navigate('/article')
                }else{
                    message.error('保存失败')
                }
            })
        } else {
            message.error('请检查必填项目')
        }

    }
    const handleEdit=()=>{
        const data = collectData()
        // console.log('hi',data)
        axios.put(url_config.articles + `${id}/`, data).then(res => {
            if (res.status === 200){
                message.success('保存成功')
                navigate('/article')
            }else{
                message.error('保存失败')
            }
        })
    }



    return (
        <WriteContainer>
            <TitleInput value={title} onChange={(e: any) => setTitle(e.target.value)}></TitleInput>
            <div style={{ border: '1px solid #ccc', zIndex: 100, marginTop: '4rem' }}>
                <Toolbar
                    editor={editor}
                    defaultConfig={toolbarConfig}
                    mode="default"
                    style={{ borderBottom: '1px solid #ccc' }}
                />
                <Editor
                    defaultConfig={editorConfig}
                    value={html}
                    onCreated={setEditor}
                    onChange={editor => setHtml(editor.getHtml())}
                    mode="default"
                    style={{ height: '500px', overflowY: 'hidden' }}
                />
            </div>
            <NextButton >
                <Button buttonType='animateIn' onClick={() => { setShowModal(true) }} buttonText='下一步' height='3rem' textAlign='center'>
                </Button>
            </NextButton>
            <Modal display={showModal} customerWidth='400px' outSideClose={() => setShowModal(false)}>
                {() => (
                    <ModalContent>
                        <h3>系列</h3>
                        <SeriesDropDown defaultSeries={series} handleSelectChange={(id: number) => { setSeries(id) }} />
                        { !id && <IsNewArticle handleDateChange={(date: string) => { setDate(date) }} /> }
                        <h3 >上传封面</h3>
                        <UploadComponent defaultImgs={img} onFileChange={onFileChange} />
                        <h3>标签</h3>
                        <TagGroups defaultSelect={selectedTag} handleSelectTag={handleSelectTag} />
                        <NextButton >
                            { !id ?<Button buttonType='animateIn' onClick={handleSubmit} buttonText='提交' height='3rem' textAlign='center'>
                            </Button>: <Button buttonType='animateIn' onClick={handleEdit} buttonText='修改' height='3rem' textAlign='center'>
                            </Button>
                            }
                        </NextButton>
                    </ModalContent>
                )}
            </Modal>


        </WriteContainer>
    )
}

export default WriteArticle