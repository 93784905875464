import styled from 'styled-components'

export const NoteBox = styled.div`
   width: 23%;
   padding: 2rem;
   box-sizing: border-box;
   box-shadow: 1px 1px 8px #d6d6d6;
   margin-top: 2.5rem;
   &:not(:nth-child(4n)){
    margin-right: calc(6% /3);
   }
`;

export const NoteTime = styled.div`
  color: #919090;
  margin-bottom: 1.2rem;
  font-size: 1.3rem;
`;
export const NoteContent = styled.div`
  color: #545353;
  font-size: 1.4rem;
  padding-left: 1.5rem;
  position: relative;
  &::before{
      content: '';
      width: 6px;
      height: 6px;
      position: absolute;
      background: #6b6a6a;
      border-radius: 50%;
      left: 0;
      top:7px;

  }
`;