import styled from 'styled-components'

export const EditorContainer = styled.div`
 height: 10rem;
 width: 50rem;
 /* width: 70rem; */
 .ql-toolbar.ql-snow {
    /* width: 70rem; */
    width: 50rem;
 }
`