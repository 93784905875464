import { Navbar, Navitem } from './navbar.style'
import { useState } from 'react'



interface Props extends React.HTMLProps<HTMLDivElement> {
    navItems: any,
    changeEvent: Function,
}

const NavBar: React.FC<Props> = (props) => {
    const { navItems, changeEvent } = props
    const [activeIndex, setActiveIndex] = useState(0)
    const handleChange = (item:any,index:number) => {
        let id = item.id ?item.id:index
        changeEvent(id).then(() => {
            setActiveIndex(index)
        })
    }
    return (
        <Navbar>
            {navItems.map((item: any, index: number) => <Navitem className={activeIndex === index ? 'active' : ''} key={index} onClick={() => handleChange(item,index)}>{item.name ? item.name : item}</Navitem>)}
        </Navbar>
    )
}

export default NavBar