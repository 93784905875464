import { IsNewArticleBox, DatePickerBox } from "./IsNewArticle.style"
import { Switch, DatePicker } from 'antd';
import { useState } from 'react'
import type { DatePickerProps } from 'antd';



interface Props{
    handleDateChange: Function
}


const IsNewArticle: React.FC<Props> = ({handleDateChange}) => {
    const [checked, setChecked] = useState(true)
    const onChange = (checked: boolean) => {
        setChecked(checked)
        if (checked){
            handleDateChange('')
        }
    };
    const onChangeDate: DatePickerProps['onChange'] = (date, dateString) => {
        handleDateChange(dateString)
    };
    return (
        <IsNewArticleBox>
            新文章
            <Switch size="small" style={{ marginLeft: '1.3rem' }} defaultChecked={checked} onChange={onChange} />
            {!checked && <DatePickerBox>
                <DatePicker onChange={onChangeDate} />
            </DatePickerBox>
            }
        </IsNewArticleBox>

    )
}

export default IsNewArticle