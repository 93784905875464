import { BtnAnimateOut, BtnContainer, RegularBtn, BtnAnimateIn, Outline } from './button.style'
import React from 'react';

const buttonTypes: any = {
    "animateOut": BtnAnimateOut,
    'regular': RegularBtn,
    "animateIn": BtnAnimateIn,
}
export interface Props extends React.HTMLProps<HTMLDivElement>{
    buttonType: string,
    textAlign?: string,
    buttonText: string,
    outlineColor?: string
    height?: string
}

const Button: React.FC<Props> = (props) => {
    const { buttonText, buttonType, ...otherProps } = props
    let CustomerButton = buttonTypes[buttonType]
    let btnText = buttonText
    return (
        <BtnContainer textAlign={props.textAlign}>
            <CustomerButton {...otherProps}>
                { props.children ?props.children: btnText}
            </CustomerButton>
            {buttonType !== 'regular' ? <Outline color={props.outlineColor}></Outline> : ''}
        </BtnContainer>
    )
}
export default Button