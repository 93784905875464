import { createContext,useState, FC } from "react";
// import { createContext, useState } from "react";

export interface User{
    refresh: string;
    access: string;
    user_email: string;
    user_id: number;
    user_name: string;
}
interface UserSet{
    currentUser: User | null,
    setCurrentUser: (user:User)=>void
}

interface Props {
    children: React.ReactNode;
}

  
//context默认的值
export const UserContext = createContext<UserSet>({
    currentUser: null,
    setCurrentUser: ()=>null
    
})

export const UserProvider: FC<Props> = ({children})=>{
    const [currentUser, setCurrentUser] = useState<User | null>(null)
    const value = {currentUser,setCurrentUser}
     //传入value在provider包裹的任何组件都可获得
    return <UserContext.Provider value={value}>{children}</UserContext.Provider>  
}



// export const UserContext = createContext({});

// export const UserProvider: FC<Props> = ({ children }) => {
//     const [currentUser, setCurrentUser] = useState({});

//     return (
//         <UserContext.Provider value={{ currentUser, setCurrentUser }}>
//             {children}
//         </UserContext.Provider>
//     )
// }

// export default UserContext;