import styled, {css}from "styled-components";

export const YearsBox = styled.div`
   font-size: 14px;
   margin-top: 3rem;
   display: flex; 
   flex-wrap: wrap;
`;
const activeStatus = css`
  /* background: #43bfe8; */
  border-bottom: 4px #43bfe8 solid;
  /* color: #ffffff; */
`

export const Year= styled.div`
  padding: 2px 2px;
  margin: 8px 20px;
  cursor: pointer;
  ${({active})=> active && activeStatus};
  box-sizing: border-box;
  
  /* border: 1px red solid; */
`;