import {
  BlogBriefContent,
  BriefTitle,
  BriefDes,
  Briefs,
  Tag,
  Time,
  Des,
  TimeConsume
} from "./BlogBrief.style";
import { Article } from "../Home/\bArticleList";
import useStorageArticle from "../../hooks/useStorageArticle";
import ReactMarkdown from 'react-markdown';


interface ArticleExtend extends Article{
  brief: string
}

interface Prop{
  article: ArticleExtend
}

const BlogBrief:React.FC<Prop> = ({article}) => {
  const {StoreArticle} = useStorageArticle()
  return (
    <BlogBriefContent>
      <div className="flex-2">
      <Briefs onClick={()=>{ StoreArticle(article)}}>
        <BriefTitle>{article?.title}</BriefTitle>
        <BriefDes>
        {article?.brief}
           {/* <div dangerouslySetInnerHTML={{__html: article?.brief}} /> */}
        </BriefDes>
      </Briefs>
      <img src={article.briefImg} className="briefImg" alt="briefImg" />
      </div>
      <Des>
          <Tag>{ article.tag[0].name }</Tag>
          <Time>{ article.deliver_time }</Time>
          <span>.</span>
          <TimeConsume>{ article.estimated_time }分钟</TimeConsume>
        </Des>
    </BlogBriefContent>
  );
};

export default BlogBrief;
