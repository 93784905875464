import styled from 'styled-components'



export const ModalContainer = styled.div`
    position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 400;
  -webkit-overflow-scrolling: touch;
`
export const ModalOverlay = styled.div`
    width: 100%;
    min-height: 100vh;
    background-color: rgba(0,0,0,0.5);
    /* z-index: 1200; */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`

export const ModalBox = styled.div`
    width: 600px;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    /* z-index: 500; */
    padding-top: 2.3rem;
    position: relative;
   

    .close-icon-outer{
        position: absolute;
        right: 10px;
        top: -10px;
        user-select: none;
        cursor: pointer;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
    }
    .close-icon{
        width: 3rem;
    }
    
`
